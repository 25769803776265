import { yupResolver } from '@hookform/resolvers/yup';
import {
    ArrowBack
} from '@mui/icons-material';
import {
    Card,
    CardActions,
    Grid,
    IconButton,
    MenuItem,
    Typography
} from '@mui/material';
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import React, { useState } from "react";
import { Controller, useForm, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import * as yup from "yup";
import { Button } from '../../components/Button';
import { LoadingBox } from '../../components/LoadingBox';
import { LoadingButton } from '../../components/LoadingButton';
import { PageName } from '../../components/PageName';
import { Select } from '../../components/Select';
import { TextField } from '../../components/TextField';
import { CardHeaderTitleStyled, FormHelperTextStyled, SubSubtitlePageForm } from '../../styles/styles';
import { languages } from '../../utils/adapterLocaleUtils';
import { displayErrorMessage } from "../../utils/errorMessage";

const validationSchema = yup.object({
    typeOfDocumentId: yup.string().required(),
    typeOfDocument: yup.string()
        .when("typeOfDocumentId", {
            is: (value: string) => value === "1" || value === "6",
            then: yup.string().required()
        }),
    documentNumber: yup.string()
        .when("typeOfDocumentId", {
            is: (value: string) => value === "1" || value === "6",
            then: yup.string()
                .required()
                .matches(/^[0-9]+$/gm, "validations.numbersOnly")
        }),
    documentIssuer: yup.string()
        .when("typeOfDocumentId", {
            is: (value: string) => value === "1" || value === "6",
            then: yup.string().required()
        }),
    documentStateIssuer: yup.string()
        .when("typeOfDocumentId", {
            is: (value: string) => value === "1" || value === "6",
            then: yup.string().required()
        }),
    documentDateOfIssue: yup.date()
        .nullable()
        .transform((curr, orig) => orig === '' ? null : curr)
        .when("typeOfDocumentId", {
            is: (value: string) => value === "1" || value === "6",
            then: yup.date()
                .typeError("Erro")
                .required()
                .min(new Date(1500, 0, 1))
                .max(new Date()),
        }),
    issueDate: yup.date()
        .nullable()
        .transform((curr, orig) => orig === '' ? null : curr)
        .when("typeOfDocumentId", {
            is: "5",
            then: yup.date()
                .typeError("Erro")
                .required()
                .min(new Date(1500, 0, 1))
                .max(new Date()),
        }),
    expirationDate: yup.date()
        .nullable()
        .transform((curr, orig) => orig === '' ? null : curr)
        .when("typeOfDocumentId", {
            is: "5",
            then: yup.date()
                .typeError("Erro")
                .required()
        }),
    addressProof: yup.mixed()
        .when("typeOfDocumentId", {
            is: "2",
            then: yup.mixed()
                .test("required", "validations.fileRequired", (value) => value.length > 0)
                .test("fileSize", "validations.fileSize", (value) => {
                    return value.length && value[0].size <= 20971520;
                })
                .test("fileType", "validations.fileType", (value) => {
                    return value.length && ["image/jpeg", "image/png", "image/jpg", "application/pdf", "image/bmp"].includes(value[0].type)
                })
        }),
    marriageProof: yup.mixed()
        .when("typeOfDocumentId", {
            is: "3",
            then: yup.mixed()
                .test("required", "validations.fileRequired", (value) => value.length > 0)
                .test("fileSize", "validations.fileSize", (value) => {
                    return value.length && value[0].size <= 20971520;
                })
                .test("fileType", "validations.fileType", (value) => {
                    return value.length && ["image/jpeg", "image/png", "image/jpg", "application/pdf", "image/bmp"].includes(value[0].type)
                })
        }),
    jointAccountProof: yup.mixed()
        .when("typeOfDocumentId", {
            is: "4",
            then: yup.mixed()
                .test("required", "validations.fileRequired", (value) => value.length > 0)
                .test("fileSize", "validations.fileSize", (value) => {
                    return value.length && value[0].size <= 20971520;
                })
                .test("fileType", "validations.fileType", (value) => {
                    return value.length && ["image/jpeg", "image/png", "image/jpg", "application/pdf", "image/bmp"].includes(value[0].type)
                })
        }),
    powerOfAttorneyProof: yup.mixed()
        .when("typeOfDocumentId", {
            is: "5",
            then: yup.mixed()
                .test("required", "validations.fileRequired", (value) => value.length > 0)
                .test("fileSize", "validations.fileSize", (value) => {
                    return value.length && value[0].size <= 20971520;
                })
                .test("fileType", "validations.fileType", (value) => {
                    return value.length && ["image/jpeg", "image/png", "image/jpg", "application/pdf", "image/bmp"].includes(value[0].type)
                })
        }),
    identificationProof: yup.mixed()
        .when("typeOfDocumentId", {
            is: (value: string) => value === "1" || value === "6",
            then: yup.mixed()
                .test("required", "validations.fileRequired", (value) => value.length > 0)
                .test("fileSize", "validations.fileSize", (value) => {
                    return value.length && value[0].size <= 20971520;
                })
                .test("fileType", "validations.fileType", (value) => {
                    return value.length && ["image/jpeg", "image/png", "image/jpg", "application/pdf", "image/bmp"].includes(value[0].type)
                })
        }),
});

export function UploadDocument() {
    const { i18n, t } = useTranslation();
    const [loading] = useState<boolean>(false);
    const [submitting] = useState<boolean>(false);

    const { control, handleSubmit, formState: { errors } } = useForm({
        resolver: yupResolver(validationSchema),
        defaultValues: {
            typeOfDocumentId: "1",
            typeOfDocument: "",
            documentNumber: "",
            documentIssuer: "",
            documentStateIssuer: "",
            documentDateOfIssue: "",
            issueDate: "",
            expirationDate: "",
            addressProof: "",
            marriageProof: "",
            jointAccountProof: "",
            powerOfAttorneyProof: "",
            identificationProof: "",
        }
    });

    const typeOfDocumentId = useWatch({
        control,
        name: "typeOfDocumentId",
    });

    const onSubmit = async (data: any) => {
    }

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Grid
                container
            >
                {loading
                    ? <LoadingBox />
                    :
                    <Grid
                        item
                        xs={12}
                        sm={12}
                        md={12}
                        lg={12}
                        xl={12}
                        sx={{
                            background: 'var(--white)',
                            borderRadius: '4px',
                            padding: '2rem',
                            '@media(max-width: 899px)': {
                                padding: '0rem',
                            }
                        }}
                    >
                        <Grid
                            container
                            spacing={3}
                        >
                            <Grid
                                item
                                xs={12}
                                sm={12}
                                md={12}
                                lg={12}
                                xl={12}
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    gap: '.5rem'
                                }}
                            >
                                <IconButton
                                    component={Link}
                                    to="/dashboard/registration-documents"
                                    sx={{
                                        color: 'var(--gray-900)'
                                    }}
                                >
                                    <ArrowBack />
                                </IconButton>
                                <PageName
                                    title={t("text.addDocument")}
                                />
                            </Grid>
                            <Grid
                                item
                                xs={12}
                                sm={12}
                                md={12}
                                lg={12}
                                xl={12}
                            >
                                <Grid
                                    container
                                    spacing={2}
                                >
                                    <Grid
                                        item
                                        xs={12}
                                        sm={12}
                                        md={12}
                                        lg={12}
                                        xl={12}
                                    >
                                        <Grid
                                            container
                                            spacing={2}
                                        >
                                            <Grid
                                                item
                                                xs={12}
                                                sm={12}
                                                md={3}
                                                lg={3}
                                                xl={3}
                                            >
                                                <Controller
                                                    control={control}
                                                    name="typeOfDocumentId"
                                                    render={({ field }) => (
                                                        <Select
                                                            value={field.value}
                                                            onChange={field.onChange}
                                                            inputRef={field.ref}
                                                            error={!!errors.typeOfDocumentId}
                                                            label={t("text.documentType") as string}
                                                            displayEmpty
                                                        >
                                                            <MenuItem value="">{t("text.select")}</MenuItem>
                                                            <MenuItem value="1">
                                                                {t("text.identificationDocumentWithPicture")}
                                                            </MenuItem>
                                                            <MenuItem value="2">
                                                                {t("text.proofOfAddress90Days")}
                                                            </MenuItem>
                                                            <MenuItem value="3">
                                                                {t("text.marriageCertificate")}
                                                            </MenuItem>
                                                            <MenuItem value="4">
                                                                {t("text.jointAccountConfirmation")}
                                                            </MenuItem>
                                                            <MenuItem value="5">
                                                                {t("text.powerOfAttorney")}
                                                            </MenuItem>
                                                            <MenuItem value="6">
                                                                {t("text.powerOfAttorneyIdentificationDocumentWithPicture")}
                                                            </MenuItem>
                                                            <MenuItem value="7">
                                                                {t("text.others")}
                                                            </MenuItem>
                                                        </Select>
                                                    )}
                                                />
                                                <FormHelperTextStyled>
                                                    {displayErrorMessage(errors.typeOfDocumentId?.message) as string}
                                                </FormHelperTextStyled>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    {(typeOfDocumentId === "1" || typeOfDocumentId === "6") && (
                                        <>
                                            <Grid
                                                item
                                                xs={12}
                                                sm={12}
                                                md={12}
                                                lg={12}
                                                xl={12}
                                            >
                                                <SubSubtitlePageForm>
                                                    {t("text.fillInAccordinglyToDocumentThatWillBeSent")}
                                                </SubSubtitlePageForm>
                                            </Grid>
                                            <Grid
                                                item
                                                xs={12}
                                                sm={12}
                                                md={2}
                                                lg={2}
                                                xl={2}
                                            >
                                                <Controller
                                                    control={control}
                                                    name="typeOfDocument"
                                                    render={({ field }) => (
                                                        <Select
                                                            value={field.value}
                                                            onChange={field.onChange}
                                                            inputRef={field.ref}
                                                            error={!!errors.typeOfDocument}
                                                            label={t("text.documentType") as string}
                                                            displayEmpty
                                                        >
                                                            <MenuItem value="">{t("text.select")}</MenuItem>
                                                            <MenuItem value="2">{t("text.cnh")}</MenuItem>
                                                            <MenuItem value="3">{t("text.rg")}</MenuItem>
                                                            <MenuItem value="4">{t("text.rne")}</MenuItem>
                                                            <MenuItem value="22">{t("text.classCard")}</MenuItem>
                                                        </Select>
                                                    )}
                                                />
                                                <FormHelperTextStyled>
                                                    {displayErrorMessage(errors.typeOfDocument?.message) as string}
                                                </FormHelperTextStyled>
                                            </Grid>
                                            <Grid
                                                item
                                                xs={12}
                                                sm={12}
                                                md={3}
                                                lg={3}
                                                xl={3}
                                            >
                                                <Controller
                                                    control={control}
                                                    name="documentNumber"
                                                    render={({ field }) => (
                                                        <TextField
                                                            value={field.value}
                                                            onChange={field.onChange}
                                                            inputRef={field.ref}
                                                            label={t("text.documentNumber")}
                                                            placeholder={t("text.documentNumber") as string}
                                                            inputProps={{ maxLength: 100 }}
                                                            fullWidth
                                                            error={!!errors.documentNumber}
                                                            helperText={displayErrorMessage(errors.documentNumber?.message) as string}
                                                            variant={'outlined'}
                                                        />
                                                    )}
                                                />
                                            </Grid>
                                            <Grid
                                                item
                                                xs={12}
                                                sm={12}
                                                md={2}
                                                lg={2}
                                                xl={2}
                                            >
                                                <Controller
                                                    control={control}
                                                    name="documentIssuer"
                                                    render={({ field }) => (
                                                        <TextField
                                                            value={field.value}
                                                            onChange={field.onChange}
                                                            inputRef={field.ref}
                                                            label={t("text.issuingBody")}
                                                            placeholder={t("text.issuingBody") as string}
                                                            inputProps={{ maxLength: 100 }}
                                                            fullWidth
                                                            error={!!errors.documentIssuer}
                                                            helperText={displayErrorMessage(errors.documentIssuer?.message) as string}
                                                            variant={'outlined'}
                                                        />
                                                    )}
                                                />
                                            </Grid>
                                            <Grid
                                                item
                                                xs={12}
                                                sm={12}
                                                md={2}
                                                lg={2}
                                                xl={2}
                                            >
                                                <Controller
                                                    control={control}
                                                    name="documentStateIssuer"
                                                    render={({ field }) => (
                                                        <Select
                                                            value={field.value}
                                                            onChange={field.onChange}
                                                            inputRef={field.ref}
                                                            error={!!errors.documentStateIssuer}
                                                            label={t("text.issuingState") as string}
                                                            displayEmpty
                                                        >
                                                            <MenuItem value="">{t("text.select")}</MenuItem>
                                                            <MenuItem value="ac">AC</MenuItem>
                                                            <MenuItem value="al">AL</MenuItem>
                                                            <MenuItem value="ap">AP</MenuItem>
                                                            <MenuItem value="am">AM</MenuItem>
                                                            <MenuItem value="ba">BA</MenuItem>
                                                            <MenuItem value="ce">CE</MenuItem>
                                                            <MenuItem value="df">DF</MenuItem>
                                                            <MenuItem value="es">ES</MenuItem>
                                                            <MenuItem value="go">GO</MenuItem>
                                                            <MenuItem value="ma">MA</MenuItem>
                                                            <MenuItem value="mt">MT</MenuItem>
                                                            <MenuItem value="ms">MS</MenuItem>
                                                            <MenuItem value="mg">MG</MenuItem>
                                                            <MenuItem value="pa">PA</MenuItem>
                                                            <MenuItem value="pb">PB</MenuItem>
                                                            <MenuItem value="pr">PR</MenuItem>
                                                            <MenuItem value="pe">PE</MenuItem>
                                                            <MenuItem value="pi">PI</MenuItem>
                                                            <MenuItem value="rj">RJ</MenuItem>
                                                            <MenuItem value="rn">RN</MenuItem>
                                                            <MenuItem value="rs">RS</MenuItem>
                                                            <MenuItem value="ro">RO</MenuItem>
                                                            <MenuItem value="rr">RR</MenuItem>
                                                            <MenuItem value="sc">SC</MenuItem>
                                                            <MenuItem value="sp">SP</MenuItem>
                                                            <MenuItem value="se">SE</MenuItem>
                                                            <MenuItem value="to">TO</MenuItem>
                                                        </Select>
                                                    )}
                                                />
                                                <FormHelperTextStyled>
                                                    {displayErrorMessage(errors.documentStateIssuer?.message) as string}
                                                </FormHelperTextStyled>
                                            </Grid>
                                            <Grid
                                                item
                                                xs={12}
                                                sm={12}
                                                md={3}
                                                lg={3}
                                                xl={3}
                                            >
                                                <LocalizationProvider
                                                    dateAdapter={AdapterDateFns}
                                                    adapterLocale={languages[i18n.language as keyof typeof languages]}
                                                >
                                                    <Controller
                                                        control={control}
                                                        name="documentDateOfIssue"
                                                        render={({ field }) => (
                                                            <DatePicker
                                                                value={field.value}
                                                                onChange={field.onChange}
                                                                inputRef={field.ref}
                                                                inputFormat="dd/MM/yyyy"
                                                                renderInput={(params) =>
                                                                    <TextField
                                                                        {...params}
                                                                        inputProps={{
                                                                            ...params.inputProps,
                                                                            placeholder: t("text.issueDate")
                                                                        }}
                                                                        label={t("text.issueDate")}
                                                                        error={!!errors.documentDateOfIssue}
                                                                        helperText={displayErrorMessage(errors.documentDateOfIssue?.message) as string}
                                                                        fullWidth
                                                                        variant={'outlined'}
                                                                    />
                                                                }
                                                            />
                                                        )}
                                                    />
                                                </LocalizationProvider>
                                            </Grid>
                                            <Grid
                                                item
                                                xs={12}
                                                sm={12}
                                                md={12}
                                                lg={12}
                                                xl={12}
                                            >
                                                <Card>
                                                    <CardHeaderTitleStyled
                                                        title={t("text.identificationDocumentWithPicture")}
                                                        subheader={t("text.identificationDocumentValidity")}
                                                    />
                                                    <CardActions>
                                                        <Typography variant="caption" display="block">
                                                            {t("text.allowedFileTypesAndSize")}
                                                        </Typography>
                                                    </CardActions>
                                                </Card>
                                                {errors.identificationProof && <FormHelperTextStyled>{displayErrorMessage(errors.identificationProof?.message) as string}</FormHelperTextStyled>}
                                            </Grid>
                                        </>
                                    )}
                                    {typeOfDocumentId === "2" && (
                                        <Grid
                                            item
                                            xs={12}
                                            sm={12}
                                            md={12}
                                            lg={12}
                                            xl={12}
                                        >
                                            <Card>
                                                <CardHeaderTitleStyled
                                                    title={t("text.proofOfAddress")}
                                                    subheader={t("text.beInTheNameOfRegistrationHolder")}
                                                />
                                                <CardActions>
                                                    <Typography variant="caption" display="block">
                                                        {t("text.allowedFileTypesAndSize")}
                                                    </Typography>
                                                </CardActions>
                                            </Card>
                                            {errors.addressProof && <FormHelperTextStyled>{displayErrorMessage(errors.addressProof?.message) as string}</FormHelperTextStyled>}
                                        </Grid>
                                    )}
                                    {typeOfDocumentId === "3" && (
                                        <Grid
                                            item
                                            xs={12}
                                            sm={12}
                                            md={12}
                                            lg={12}
                                            xl={12}
                                        >
                                            <Card>
                                                <CardHeaderTitleStyled
                                                    title={t("text.marriageCertificate")}
                                                    subheader={t("text.attachProofOfAddressIfPartnerName")}
                                                />
                                                <CardActions>
                                                    <Typography variant="caption" display="block">
                                                        {t("text.allowedFileTypesAndSize")}
                                                    </Typography>
                                                </CardActions>
                                            </Card>
                                            {errors.marriageProof && <FormHelperTextStyled>{displayErrorMessage(errors.marriageProof?.message) as string}</FormHelperTextStyled>}
                                        </Grid>
                                    )}
                                    {typeOfDocumentId === "4" && (
                                        <Grid
                                            item
                                            xs={12}
                                            sm={12}
                                            md={12}
                                            lg={12}
                                            xl={12}
                                        >
                                            <Card>
                                                <CardHeaderTitleStyled
                                                    title={t("text.jointAccountConfirmation")}
                                                    subheader={t("text.copyOfCheckStatementCardEtc")}
                                                />
                                                <CardActions>
                                                    <Typography variant="caption" display="block">
                                                        {t("text.allowedFileTypesAndSize")}
                                                    </Typography>
                                                </CardActions>
                                            </Card>
                                            {errors.jointAccountProof && <FormHelperTextStyled>{displayErrorMessage(errors.jointAccountProof?.message) as string}</FormHelperTextStyled>}
                                        </Grid>
                                    )}
                                    {typeOfDocumentId === "5" && (
                                        <>
                                            <Grid
                                                item
                                                xs={12}
                                                sm={12}
                                                md={12}
                                                lg={12}
                                                xl={12}
                                            >
                                                <SubSubtitlePageForm>
                                                    {t("text.fillInAccordinglyToDocumentThatWillBeSent")}
                                                </SubSubtitlePageForm>
                                            </Grid>
                                            <Grid
                                                item
                                                xs={12}
                                                sm={12}
                                                md={3}
                                                lg={3}
                                                xl={3}
                                            >
                                                <LocalizationProvider
                                                    dateAdapter={AdapterDateFns}
                                                    adapterLocale={languages[i18n.language as keyof typeof languages]}
                                                >
                                                    <Controller
                                                        control={control}
                                                        name="issueDate"
                                                        render={({ field }) => (
                                                            <DatePicker
                                                                value={field.value}
                                                                onChange={field.onChange}
                                                                inputRef={field.ref}
                                                                inputFormat="dd/MM/yyyy"
                                                                renderInput={(params) =>
                                                                    <TextField
                                                                        {...params}
                                                                        inputProps={{
                                                                            ...params.inputProps,
                                                                            placeholder: t("text.powerOfAttorneyIssueDate")
                                                                        }}
                                                                        label={t("text.powerOfAttorneyIssueDate")}
                                                                        error={!!errors.issueDate}
                                                                        helperText={displayErrorMessage(errors.issueDate?.message) as string}
                                                                        fullWidth
                                                                        variant='outlined'
                                                                    />
                                                                }
                                                            />
                                                        )}
                                                    />
                                                </LocalizationProvider>
                                            </Grid>
                                            <Grid
                                                item
                                                xs={12}
                                                sm={12}
                                                md={3}
                                                lg={3}
                                                xl={3}
                                            >
                                                <LocalizationProvider
                                                    dateAdapter={AdapterDateFns}
                                                    adapterLocale={languages[i18n.language as keyof typeof languages]}
                                                >
                                                    <Controller
                                                        control={control}
                                                        name="expirationDate"
                                                        render={({ field }) => (
                                                            <DatePicker
                                                                value={field.value}
                                                                onChange={field.onChange}
                                                                inputRef={field.ref}
                                                                inputFormat="dd/MM/yyyy"
                                                                renderInput={(params) =>
                                                                    <TextField
                                                                        {...params}
                                                                        inputProps={{
                                                                            ...params.inputProps,
                                                                            placeholder: t("text.powerOfAttorneyExpirationDate")
                                                                        }}
                                                                        label={t("text.powerOfAttorneyExpirationDate")}
                                                                        error={!!errors.expirationDate}
                                                                        helperText={displayErrorMessage(errors.expirationDate?.message) as string}
                                                                        fullWidth
                                                                        variant='outlined'
                                                                    />
                                                                }
                                                            />
                                                        )}
                                                    />
                                                </LocalizationProvider>
                                            </Grid>
                                            <Grid
                                                item
                                                xs={12}
                                                sm={12}
                                                md={12}
                                                lg={12}
                                                xl={12}
                                            >
                                                <Card>
                                                    <CardHeaderTitleStyled
                                                        title={t("text.powerOfAttorney")}
                                                        subheader={t("text.attachDocument")}
                                                    />
                                                    <CardActions>
                                                        <Typography variant="caption" display="block">
                                                            {t("text.allowedFileTypesAndSize")}
                                                        </Typography>
                                                    </CardActions>
                                                </Card>
                                                {errors.powerOfAttorneyProof && <FormHelperTextStyled>{displayErrorMessage(errors.powerOfAttorneyProof?.message) as string}</FormHelperTextStyled>}
                                            </Grid>
                                        </>
                                    )}
                                </Grid>
                            </Grid>
                            <Grid
                                item
                                xs={12}
                                sm={12}
                                md={12}
                                lg={12}
                                xl={12}
                            >
                                <Grid
                                    container
                                    spacing={2}
                                >
                                    <Grid
                                        item
                                        xs={6}
                                        sm={6}
                                        md={6}
                                        lg={6}
                                        xl={6}
                                    >
                                        <Button
                                            variant="text"
                                            label={t("text.cancel")}
                                            component={Link}
                                            to="/dashboard/registration-documents"
                                            fullWidth
                                        />
                                    </Grid>
                                    <Grid
                                        item
                                        xs={6}
                                        sm={6}
                                        md={6}
                                        lg={6}
                                        xl={6}
                                    >
                                        <LoadingButton
                                            type="submit"
                                            variant="contained"
                                            label={t("text.add")}
                                            loading={submitting}
                                            fullWidth
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>}
            </Grid>
        </form >
    );
}