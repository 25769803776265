import api from "./api";
import { IAttorney } from '../interfaces/attorney'
import { IIncomeAndPowerOfAttorney } from "../interfaces/income";

export const getIncomeAndPowerOfAttorney = async () => {
    return await api.get(`/api/client/income-attorney`, { withCredentials: true });
}

export const saveIncomeAndPowerOfAttorney = async (data: IIncomeAndPowerOfAttorney, updateStepIndex = true) => {
    return await api.put(`/api/client/income-attorney`, data, { withCredentials: true, headers: { 'X-UpdateStepIndex': updateStepIndex } });
}

export const getAttorneys = async () => {
    return await api.get(`/api/attorney/client`, { withCredentials: true });
}

export const getAttorneyByIdPromise = async (id: string) => api.get(`/api/attorney/${id}`, { withCredentials: true });

export const saveAttorney = async (data: IAttorney) => {
    return await api.post(`/api/attorney`, data, { withCredentials: true });
}

export const updateAttorney = async (id: string, data: IAttorney) => {
    return await api.patch(`/api/attorney/${id}`, data, { withCredentials: true });
}

export const deleteAttorney = async (id: string) => {
    return await api.delete(`/api/attorney/${id}`, { withCredentials: true });
}