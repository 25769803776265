import { formatCPF, isValidCPF, isValidEmail } from '@brazilian-utils/brazilian-utils';
import { yupResolver } from '@hookform/resolvers/yup';
import { Add } from '@mui/icons-material';
import {
    Card,
    CardActions,
    Divider,
    FormControl,
    Grid,
    Radio,
    RadioGroup,
    Typography
} from '@mui/material';
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { sub } from 'date-fns';
import { saveAs } from 'file-saver';
import React, { useContext, useEffect, useState, useRef } from "react";
import { Controller, useFieldArray, useForm, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from "react-router-dom";
import { toast } from 'react-toastify';
import * as yup from "yup";
import { Button } from '../../../components/Button';
import { CRMAddressStateOptions } from '../../../components/CRMAddressStateOptions';
import { CRMDocumentTypeOptions } from '../../../components/CRMDocumentTypeOptions';
import Document from "../../../components/Document";
import DocumentAdditional from '../../../components/DocumentAdditional';
import { DocumentAlert } from '../../../components/DocumentAlert';
import { DocumentHead } from '../../../components/DocumentHead';
import { DocumentSubtitle } from '../../../components/DocumentSubtitle';
import { LoadingBox } from '../../../components/LoadingBox';
import { LoadingButton } from "../../../components/LoadingButton";
import { OnboardingStepperRegister } from '../../../components/OnboardingStepperRegister';
import { PageName } from '../../../components/PageName';
import { TextField } from '../../../components/TextField';
import { AuthContext } from "../../../contexts/auth";
import { getPersonalDataRegisterPromise, savePersonalDataRegister } from '../../../services/clientService';
import { getAddressStatePromise } from '../../../services/crmAddressState';
import { getCRMDocumentTypePromise } from '../../../services/crmDocumentTypeService';
import {
    FormControlLabelStyled,
    FormHelperTextStyled,
    FormLabelInfoStyled,
    FormLabelStyled,
    SubtitlePageForm,
} from '../../../styles/styles';
import { languages } from '../../../utils/adapterLocaleUtils';
import { displayErrorMessage } from "../../../utils/errorMessage";
import { toBase64 } from "../../../utils/fileUtils";
import * as masks from "../../../utils/masks";
import { CRMDdiOptions } from '../../../components/CRMDdiOptions';
import { getCRMDdiPromise } from '../../../services/crmDdi';
import { stripToEmpty } from "../../../utils/stringUtils";
import { removeSpecialCharactersAndAccents } from "../../../utils/stringUtils";
import { hasCellPhone, hasNumber, hasPhone } from '../../../utils/phoneUtils';

const validationSchema = yup.object({
    productId: yup.string(),
    estimatedValueId: yup.string(),
    taxId: yup.string()
        .required()
        .transform(masks.cpfMask.transform)
        .test("validateCPF", (value) => {
            return isValidCPF(value!)
        }),
    name: yup.string()
        .required()
        .min(2),
    dateOfBirth: yup.date()
        .when("productId", (productId) => {
            if (productId === "6c7e3226-bd70-ed11-81ac-0022481b5e08")
                return yup.date().typeError("Erro").required().max(sub(new Date(), { years: 16 }), "validations.under16")
            else
                return yup.date().typeError("Erro").required().max(sub(new Date(), { years: 18 }), "validations.under18")
        }),
    birthCity: yup.string().required(),
    birthState: yup.string().required(),
    motherName: yup.string()
        .required()
        .min(2),
    cellPhoneDdi: yup.string().nullable()
        .test('hasNumber', 'Campo obrigatório', (value, context) => {
            return hasNumber(context);
        })
        .test('hasCellPhone', 'Campo obrigatório', (value, context) => {
            return hasCellPhone(context);
        }),
    cellPhoneDdd: yup.string().nullable()
        .test('hasNumber', 'validations.required', (value, context) => {
            return hasNumber(context);
        })
        .test('hasCellPhone', 'validations.required', (value, context) => {
            return hasCellPhone(context);
        })
        .matches(/^[0-9]+$/gm, { message: "validations.numbersOnly", excludeEmptyString: true }),
    cellPhone: yup.string().nullable()
        .test('hasCellPhone', 'validations.required', (value, context) => {
            return hasNumber(context);
        })
        .test('hasPhone', 'validations.required', (value, context) => {
            return hasCellPhone(context);
        })
        .matches(/^[0-9]+$/gm, { message: "validations.numbersOnly", excludeEmptyString: true }),
    phoneDdi: yup.string().nullable()
        .test('hasNumber', 'validations.required', (value, context) => {
            return hasNumber(context);
        })
        .test('hasPhone', 'validations.required', (value, context) => {
            return hasPhone(context);
        }),
    phoneDdd: yup.string().nullable()
        .test('hasNumber', 'validations.required', (value, context) => {
            return hasNumber(context);
        })
        .test('hasPhone', 'validations.required', (value, context) => {
            return hasPhone(context);
        })
        .matches(/^[0-9]+$/gm, { message: "validations.numbersOnly", excludeEmptyString: true }),
    phone: yup.string().nullable()
        .test('custom', 'validations.required', (value, context) => {
            return hasNumber(context);
        })
        .test('hasPhone', 'validations.required', (value, context) => {
            return hasPhone(context);
        })
        .matches(/^[0-9]+$/gm, { message: "validations.numbersOnly", excludeEmptyString: true }),
    email: yup.string()
        .required()
        .min(10)
        .transform((curr, orig) => stripToEmpty(curr))
        .test("validateEmail", (value) => isValidEmail(stripToEmpty(value))),
    document: yup.object({
        hasDriverLicense: yup.string()
            .required(),
        documentTypeId: yup.string()
            .when("hasDriverLicense", {
                is: "2",
                then: yup.string().required()
            }),
        documentNumber: yup.string()
            .required()
            .matches(/^[a-zA-Z0-9]+$/, "validations.notSpecialCharacter"),
        documentIssuer: yup.string().required(),
        documentStateIssuer: yup.string().required(),
        documentDateOfIssue: yup.date()
            .nullable()
            .transform((curr, orig) => orig === '' ? null : curr)
            .typeError("Erro")
            .required()
            .min(new Date(1500, 0, 1))
            .max(new Date()),
    }),
    usPerson: yup.string().required(),
    nif: yup.string()
        .when("usPerson", {
            is: "1",
            then: yup.string().required()
        }),
    isPep: yup.string().required(),
    isPepExplain: yup.string()
        .when("isPep", {
            is: "120240000",
            then: yup.string().required()
        }),
    proof: yup.mixed()
        .test("required", "validations.fileRequired", (value) => value.length > 0)
        .test("fileSize", "validations.fileSize", (value) => {
            return value.length && value[0].size <= 20971520;
        })
        .test("fileType", "validations.fileType", (value) => {
            return value.length && ["image/jpeg", "image/png", "application/pdf", "image/bmp"].includes(value[0].type)
        }),
    documents: yup.array()
        .of(
            yup.object().shape({
                id: yup.string(),
                documentProof: yup.mixed()
                    .test("required", "validations.fileRequired", (value) => value.length > 0)
                    .test("fileSize", "validations.fileSize", (value) => {
                        return value.length && value[0].size <= 20971520;
                    })
                    .test("fileType", "validations.fileType", (value) => {
                        return value.length && ["image/jpeg", "image/png", "image/jpg", "application/pdf", "image/bmp"].includes(value[0].type)
                    }),
            })
        ),
});

export function PersonalDataRegister() {
    const { i18n, t } = useTranslation();
    const navigate = useNavigate();
    const { user, handleSignOut } = useContext(AuthContext);
    const [loading, setLoading] = useState<boolean>(true);
    const [submitting, setSubmitting] = useState<boolean>(false);
    const [order, setOrder] = useState<string>("");
    const [addressStateOptions, setAddressStateOptions] = useState([]);
    const [documentTypeOptions, setDocumentTypeOptions] = useState([]);
    const [uploading, setUploading] = useState<boolean>(false);
    const [ddis, setDdis] = useState([]);
    const proofRef = useRef<any>(null);

    const { control, handleSubmit, formState: { errors }, reset, resetField, register, getValues, setValue } = useForm({
        resolver: yupResolver(validationSchema),
        defaultValues: {
            productId: "",
            estimatedValueId: "",
            taxId: "",
            name: "",
            dateOfBirth: "",
            birthCity: "",
            birthState: "",
            motherName: "",
            contactId: null,
            phoneDdi: "",
            phoneDdd: "",
            phone: "",
            cellPhoneDdi: "",
            cellPhoneDdd: "",
            cellPhone: "",
            email: "",
            document: {
                documentId: "",
                hasDriverLicense: "",
                documentTypeId: "",
                documentNumber: "",
                documentIssuer: "",
                documentStateIssuer: "",
                documentDateOfIssue: "",
            },
            usPerson: "",
            nif: "",
            isPep: "",
            isPepExplain: "",
            proof: "",
        }
    });

    const { fields, append, remove } = useFieldArray<any, string, "keyName">({
        control,
        name: "documents",
        keyName: "keyName",
    });

    const isPep = useWatch({
        control,
        name: "isPep",
    });

    const usPerson = useWatch({
        control,
        name: "usPerson",
    });

    const hasDriverLicense = useWatch({
        control,
        name: "document.hasDriverLicense",
    });

    const documentTypeId = useWatch({
        control,
        name: "document.documentTypeId",
    });

    const estimatedValueId = useWatch({
        control,
        name: "estimatedValueId",
    });

    useEffect(() => {
        async function fetchPersonalData() {
            try {

                const [addressStateOptions, documentTypeOptions, ddisOptions, personalDataRegister] = await Promise.all([
                    getAddressStatePromise(),
                    getCRMDocumentTypePromise(),
                    getCRMDdiPromise(),
                    getPersonalDataRegisterPromise()
                ]);

                setAddressStateOptions(addressStateOptions.data);
                setDocumentTypeOptions(documentTypeOptions.data);
                setDdis(ddisOptions.data);

                const _responseData = personalDataRegister.data;

                const document = _responseData.document;

                var _proof: any;
                if (document?.id) {
                    _proof = new File(["proof"], document.fileOriginalName, {
                        type: document.fileType,
                    });
                    _proof.id = document.id;
                }

                let responseData = {
                    ..._responseData,
                    taxId: formatCPF(personalDataRegister.data.taxId),
                    proof: _proof ? [].concat(_proof) : "",
                };

                if (responseData?.documents) {
                    let documents: any[] = [];
                    for (let i = 0; i < responseData.documents.length; i = i + 1) {

                        var _documentProof: any = new File(["documentProof"], responseData.documents[i].fileOriginalName, {
                            type: responseData.documents[i].fileType,
                        });
                        _documentProof.id = responseData.documents[i].id;


                        documents.push({
                            id: responseData.documents[i].id,
                            documentTypeId: responseData.documents[i].id,
                            documentProof: _documentProof ? [].concat(_documentProof) : "",
                        });
                    }

                    responseData = {
                        ...responseData,
                        documents: documents
                    };
                }

                reset(responseData);
                setOrder(responseData.order);
                setLoading(false);
            } catch (error: any) {
                const errorCode = error.response?.data?.errorCode;
                if (errorCode === "12000") {
                    navigate('/dashboard');
                } else {
                    const errorMessage = error.response?.data?.message || error.message;
                    toast.error(errorMessage);
                }
            }
        }

        fetchPersonalData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const ddiPhoneChanged = () => {
        setValue('phoneDdd', '');
        setValue('phone', '');
    }

    const ddiCellPhoneChanged = () => {
        setValue('cellPhoneDdd', '');
        setValue('cellPhone', '');
    }

    const onSubmit = async (data: any) => {
        setSubmitting(true);
        try {
            let newData = data;
            const _proof: any = getValues("proof")[0];
            if (!_proof.id) {
                const result = await toBase64(_proof);
                newData = {
                    ...data,
                    document: {
                        ...data.document,
                        fileOriginalName: _proof.name,
                        fileInBase64: result,
                        fileSize: _proof.size,
                        fileType: _proof.type,
                    }
                }

                delete newData.proof;
            }

            if (data?.documents) {
                let documentProofs: any[] = [];
                for (let i = 0; i < data.documents.length; i = i + 1) {
                    let documentProof = data.documents[i].documentProof[0];
                    const result = !documentProof.id ? await toBase64(documentProof) : null;
                    documentProofs.push({
                        id: documentProof.id,
                        fileOriginalName: documentProof.name,
                        fileInBase64: result,
                        fileSize: documentProof.size,
                        fileType: documentProof.type
                    });
                }

                newData = {
                    ...newData,
                    documents: documentProofs
                }
            }

            await savePersonalDataRegister(newData);
            navigate(`/onboarding/address-register`);
        } catch (error: any) {
            const errorMessage = error.response?.data?.message || error.message;
            toast.error(errorMessage);
        } finally {
            setSubmitting(false);
        }
    }

    const cannotAddDocument = () => {
        return fields.length !== 0 || uploading || (hasDriverLicense === "" || (hasDriverLicense === "2" && (documentTypeId === "")));
    }

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Grid
                container
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    padding: '2rem',
                }}
            >
                {loading
                    ? <LoadingBox />
                    : <React.Fragment>
                        <OnboardingStepperRegister
                            estimatedValueId={estimatedValueId}
                            activeStep={1}
                        />
                        <Grid
                            item
                            xs={12}
                            sm={12}
                            md={12}
                            lg={10}
                            xl={10}
                            sx={{
                                background: 'var(--white)',
                                borderRadius: '4px',
                                padding: '2rem',
                                '@media(max-width: 899px)': {
                                    padding: '0rem',
                                }
                            }}
                        >
                            <Grid
                                container
                                spacing={3}
                            >
                                <Grid
                                    item
                                    xs={12}
                                    sm={12}
                                    md={12}
                                    lg={12}
                                    xl={12}
                                >
                                    <PageName
                                        title={t("text.personalData")}
                                        info={t("text.toProceedWithTheRegistrationPleaseFillInTheInformationBelow")}
                                    />
                                </Grid>
                                <Grid
                                    item
                                    xs={12}
                                    sm={12}
                                    md={12}
                                    lg={12}
                                    xl={12}
                                >
                                    <Grid
                                        container
                                        spacing={2}
                                    >
                                        <Grid
                                            item
                                            xs={12}
                                            sm={12}
                                            md={3}
                                            lg={3}
                                            xl={3}
                                        >
                                            <Controller
                                                control={control}
                                                name="taxId"
                                                render={({ field }) => (
                                                    <TextField
                                                        value={field.value}
                                                        onChange={(e) => {
                                                            e.persist();
                                                            masks.cpfMask.onChange(e);
                                                            field.onChange(e);
                                                        }}
                                                        inputRef={field.ref}
                                                        label={t("text.cpf")}
                                                        placeholder={t("text.cpf") as string}
                                                        fullWidth
                                                        error={!!errors.taxId}
                                                        helperText={displayErrorMessage(errors.taxId?.message) as string}
                                                        disabled
                                                        variant='outlined'
                                                    />
                                                )}
                                            />
                                        </Grid>
                                        <Grid
                                            item
                                            xs={12}
                                            sm={12}
                                            md={6}
                                            lg={6}
                                            xl={6}
                                        >
                                            <Controller
                                                control={control}
                                                name="name"
                                                render={({ field }) => (
                                                    <TextField
                                                        value={field.value}
                                                        onChange={(e) => {
                                                            const cleanedValue = removeSpecialCharactersAndAccents(e.target.value);
                                                            field.onChange(cleanedValue);
                                                        }}
                                                        inputRef={field.ref}
                                                        label={t("text.fullName")}
                                                        placeholder={t("text.fullName") as string}
                                                        inputProps={{ maxLength: 100 }}
                                                        fullWidth
                                                        error={!!errors.name}
                                                        helperText={displayErrorMessage(errors.name?.message) as string}
                                                        variant='outlined'
                                                    />
                                                )}
                                            />
                                        </Grid>
                                        <Grid
                                            item
                                            xs={12}
                                            sm={12}
                                            md={3}
                                            lg={3}
                                            xl={3}
                                        >
                                            <LocalizationProvider
                                                dateAdapter={AdapterDateFns}
                                                adapterLocale={languages[i18n.language as keyof typeof languages]}
                                            >
                                                <Controller
                                                    control={control}
                                                    name="dateOfBirth"
                                                    render={({ field }) => (
                                                        <DatePicker
                                                            value={field.value}
                                                            onChange={field.onChange}
                                                            inputRef={field.ref}
                                                            inputFormat="dd/MM/yyyy"
                                                            renderInput={(params) =>
                                                                <TextField
                                                                    {...params}
                                                                    inputProps={{
                                                                        ...params.inputProps,
                                                                        placeholder: t("text.dob")
                                                                    }}
                                                                    label={t("text.dob")}
                                                                    error={!!errors.dateOfBirth}
                                                                    helperText={displayErrorMessage(errors.dateOfBirth?.message) as string}
                                                                    fullWidth
                                                                    variant='outlined'
                                                                />
                                                            }
                                                        />
                                                    )}
                                                />
                                            </LocalizationProvider>
                                        </Grid>
                                        <Grid
                                            item
                                            xs={12}
                                            sm={12}
                                            md={9}
                                            lg={9}
                                            xl={9}
                                        >
                                            <Controller
                                                control={control}
                                                name="birthCity"
                                                render={({ field }) => (
                                                    <TextField
                                                        value={field.value}
                                                        onChange={(e) => {
                                                            const cleanedValue = removeSpecialCharactersAndAccents(e.target.value);
                                                            field.onChange(cleanedValue);
                                                        }}
                                                        inputRef={field.ref}
                                                        label={t("text.placeOfBirth")}
                                                        placeholder={t("text.placeOfBirth") as string}
                                                        inputProps={{ maxLength: 100 }}
                                                        fullWidth
                                                        error={!!errors.birthCity}
                                                        helperText={displayErrorMessage(errors.birthCity?.message) as string}
                                                        variant='outlined'
                                                    />
                                                )}
                                            />
                                        </Grid>
                                        <Grid
                                            item
                                            xs={12}
                                            sm={12}
                                            md={3}
                                            lg={3}
                                            xl={3}
                                        >
                                            <CRMAddressStateOptions
                                                name="birthState"
                                                label={t("text.stateOfBirth")}
                                                control={control}
                                                states={addressStateOptions}
                                                error={!!errors.birthState}
                                            />
                                            <FormHelperTextStyled>
                                                {displayErrorMessage(errors.birthState?.message) as string}
                                            </FormHelperTextStyled>
                                        </Grid>
                                        <Grid
                                            item
                                            xs={12}
                                            sm={12}
                                            md={12}
                                            lg={12}
                                            xl={12}
                                        >
                                            <Controller
                                                control={control}
                                                name="motherName"
                                                render={({ field }) => (
                                                    <TextField
                                                        value={field.value}
                                                        onChange={(e) => {
                                                            const cleanedValue = removeSpecialCharactersAndAccents(e.target.value);
                                                            field.onChange(cleanedValue);
                                                        }}
                                                        inputRef={field.ref}
                                                        label={t("text.fullMotherName")}
                                                        placeholder={t("text.fullName") as string}
                                                        inputProps={{ maxLength: 100 }}
                                                        fullWidth
                                                        error={!!errors.motherName}
                                                        helperText={displayErrorMessage(errors.motherName?.message) as string}
                                                        variant='outlined'
                                                    />
                                                )}
                                            />
                                        </Grid>
                                        <Grid
                                            item
                                            xs={12}
                                            sm={12}
                                            md={2}
                                            lg={2}
                                            xl={2}
                                        >
                                            <CRMDdiOptions
                                                control={control}
                                                label={t("text.landlineDDI")}
                                                name="phoneDdi"
                                                ddis={ddis}
                                                error={!!errors.phoneDdi}
                                                ddiChanged={ddiPhoneChanged}
                                            />
                                            <FormHelperTextStyled>
                                                {displayErrorMessage(errors.phoneDdi?.message) as string}
                                            </FormHelperTextStyled>
                                        </Grid>
                                        <Grid
                                            item
                                            xs={12}
                                            sm={12}
                                            md={1}
                                            lg={1}
                                            xl={1}
                                        >
                                            <Controller
                                                control={control}
                                                name="phoneDdd"
                                                render={({ field }) => (
                                                    <TextField
                                                        value={field.value}
                                                        onChange={(e) => {
                                                            const cleanedValue = removeSpecialCharactersAndAccents(e.target.value);
                                                            field.onChange(cleanedValue);
                                                        }}
                                                        inputRef={field.ref}
                                                        label={t("text.landlineDDD")}
                                                        inputProps={{ maxLength: 3 }}
                                                        fullWidth
                                                        error={!!errors.phoneDdd}
                                                        helperText={displayErrorMessage(errors.phoneDdd?.message) as string}
                                                        variant='outlined'
                                                    />
                                                )}
                                            />
                                        </Grid>
                                        <Grid
                                            item
                                            xs={12}
                                            sm={12}
                                            md={2}
                                            lg={2}
                                            xl={2}
                                        >
                                            <Controller
                                                control={control}
                                                name="phone"
                                                render={({ field }) => (
                                                    <TextField
                                                        value={field.value}
                                                        onChange={(e) => {
                                                            const cleanedValue = removeSpecialCharactersAndAccents(e.target.value);
                                                            field.onChange(cleanedValue);
                                                        }}
                                                        inputRef={field.ref}
                                                        label={t("text.landline")}
                                                        inputProps={{ maxLength: 20 }}
                                                        fullWidth
                                                        error={!!errors.phone}
                                                        helperText={displayErrorMessage(errors.phone?.message) as string}
                                                        variant='outlined'
                                                    />
                                                )}
                                            />
                                        </Grid>
                                        <Grid
                                            item
                                            xs={12}
                                            sm={12}
                                            md={2}
                                            lg={2}
                                            xl={2}
                                        >
                                            <CRMDdiOptions
                                                control={control}
                                                label={t("text.cellphoneDDI")}
                                                name="cellPhoneDdi"
                                                ddis={ddis}
                                                error={!!errors.cellPhoneDdi}
                                                ddiChanged={ddiCellPhoneChanged}
                                            />
                                            <FormHelperTextStyled>
                                                {displayErrorMessage(errors.cellPhoneDdi?.message) as string}
                                            </FormHelperTextStyled>
                                        </Grid>
                                        <Grid
                                            item
                                            xs={12}
                                            sm={12}
                                            md={1}
                                            lg={1}
                                            xl={1}
                                        >
                                            <Controller
                                                control={control}
                                                name="cellPhoneDdd"
                                                render={({ field }) => (
                                                    <TextField
                                                        value={field.value}
                                                        onChange={(e) => {
                                                            const cleanedValue = removeSpecialCharactersAndAccents(e.target.value);
                                                            field.onChange(cleanedValue);
                                                        }}
                                                        inputRef={field.ref}
                                                        label={t("text.cellphoneDDD")}
                                                        inputProps={{ maxLength: 3 }}
                                                        fullWidth
                                                        error={!!errors.cellPhoneDdd}
                                                        helperText={displayErrorMessage(errors.cellPhoneDdd?.message) as string}
                                                        variant='outlined'
                                                    />
                                                )}
                                            />
                                        </Grid>
                                        <Grid
                                            item
                                            xs={12}
                                            sm={12}
                                            md={2}
                                            lg={2}
                                            xl={2}
                                        >
                                            <Controller
                                                control={control}
                                                name="cellPhone"
                                                render={({ field }) => (
                                                    <TextField
                                                        value={field.value}
                                                        onChange={(e) => {
                                                            const cleanedValue = removeSpecialCharactersAndAccents(e.target.value);
                                                            field.onChange(cleanedValue);
                                                        }}
                                                        inputRef={field.ref}
                                                        label={t("text.cellphone")}
                                                        inputProps={{ maxLength: 20 }}
                                                        fullWidth
                                                        error={!!errors.cellPhone}
                                                        helperText={displayErrorMessage(errors.cellPhone?.message) as string}
                                                        variant='outlined'
                                                    />
                                                )}
                                            />
                                        </Grid>
                                        <Grid
                                            item
                                            xs={12}
                                            sm={12}
                                            md={12}
                                            lg={12}
                                            xl={12}
                                        >
                                            <Controller
                                                control={control}
                                                name="email"
                                                render={({ field }) => (
                                                    <TextField
                                                        value={field.value}
                                                        onChange={field.onChange}
                                                        inputRef={field.ref}
                                                        label={t("text.email")}
                                                        placeholder={t("text.email") as string}
                                                        type="email"
                                                        inputProps={{ maxLength: 100 }}
                                                        fullWidth
                                                        error={!!errors.email}
                                                        helperText={displayErrorMessage(errors.email?.message) as string}
                                                        variant='outlined'
                                                    />
                                                )}
                                            />
                                        </Grid>
                                        <Grid
                                            item
                                            xs={12}
                                            sm={12}
                                            md={12}
                                            lg={12}
                                            xl={12}
                                        >
                                            <DocumentAlert
                                                hasDriverLicense={hasDriverLicense}
                                                typeOfDocument={documentTypeId}
                                            />
                                        </Grid>
                                        <Grid
                                            item
                                            xs={12}
                                            sm={12}
                                            md={12}
                                            lg={12}
                                            xl={12}
                                        >
                                            <Grid
                                                container
                                                spacing={2}
                                            >
                                                <Grid
                                                    item
                                                    xs={12}
                                                    sm={12}
                                                    md={12}
                                                    lg={12}
                                                    xl={12}
                                                >
                                                    <FormControl>
                                                        <FormLabelStyled>
                                                            {t("text.hasCNH")}
                                                        </FormLabelStyled>
                                                        <Controller
                                                            control={control}
                                                            name="document.hasDriverLicense"
                                                            render={({ field }) => {
                                                                return (
                                                                    <RadioGroup
                                                                        row
                                                                        onChange={(e) => {
                                                                            field.onChange(e);
                                                                            resetField("document.documentTypeId");
                                                                            if (proofRef.current !== null)
                                                                                proofRef.current.removeFile();
                                                                            remove(0);
                                                                        }}
                                                                        value={field.value}
                                                                    //{...field}
                                                                    >
                                                                        <FormControlLabelStyled
                                                                            value="1"
                                                                            control={<Radio />}
                                                                            label={t("text.yes")}
                                                                            disabled={uploading}
                                                                        />
                                                                        <FormControlLabelStyled
                                                                            value="2"
                                                                            control={<Radio />}
                                                                            label={t("text.no")}
                                                                            disabled={uploading}
                                                                        />
                                                                    </RadioGroup>
                                                                );
                                                            }}
                                                        />
                                                    </FormControl>
                                                    {errors.document?.hasDriverLicense
                                                        ? <FormHelperTextStyled>{displayErrorMessage(errors.document?.hasDriverLicense?.message) as string}</FormHelperTextStyled>
                                                        : null
                                                    }
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid
                                            item
                                            xs={12}
                                            sm={12}
                                            md={12}
                                            lg={12}
                                            xl={12}
                                        >
                                            <Grid
                                                container
                                                spacing={2}
                                            >
                                                {hasDriverLicense === "2"
                                                    ? <Grid
                                                        item
                                                        xs={12}
                                                        sm={12}
                                                        md={2}
                                                        lg={2}
                                                        xl={2}
                                                    >
                                                        <CRMDocumentTypeOptions
                                                            control={control}
                                                            name="document.documentTypeId"
                                                            documentTypes={documentTypeOptions}
                                                            error={!!errors.document && !!errors.document.documentTypeId}
                                                            disabled={uploading}
                                                        />
                                                        <FormHelperTextStyled>
                                                            {displayErrorMessage(errors.document?.documentTypeId?.message) as string}
                                                        </FormHelperTextStyled>
                                                    </Grid>
                                                    : null
                                                }
                                                <Grid
                                                    item
                                                    xs={12}
                                                    sm={12}
                                                    md={3}
                                                    lg={3}
                                                    xl={3}
                                                >
                                                    <Controller
                                                        control={control}
                                                        name="document.documentNumber"
                                                        render={({ field }) => (
                                                            <TextField
                                                                value={field.value}
                                                                onChange={(e) => {
                                                                    const cleanedValue = removeSpecialCharactersAndAccents(e.target.value);
                                                                    field.onChange(cleanedValue);
                                                                }}
                                                                inputRef={field.ref}
                                                                label={(hasDriverLicense === "2") ? t("text.documentNumber") : t("text.cnhNumber")}
                                                                placeholder={(hasDriverLicense === "2") ? t("text.documentNumber") as string : t("text.cnhNumber") as string}
                                                                inputProps={{ maxLength: 100 }}
                                                                fullWidth
                                                                error={!!errors.document && !!errors.document.documentNumber}
                                                                helperText={displayErrorMessage(errors.document?.documentNumber?.message) as string}
                                                                variant='outlined'
                                                            />
                                                        )}
                                                    />
                                                </Grid>
                                                <Grid
                                                    item
                                                    xs={12}
                                                    sm={12}
                                                    md={hasDriverLicense === "2" ? 2 : 3}
                                                    lg={hasDriverLicense === "2" ? 2 : 3}
                                                    xl={hasDriverLicense === "2" ? 2 : 3}
                                                >
                                                    <Controller
                                                        control={control}
                                                        name="document.documentIssuer"
                                                        render={({ field }) => (
                                                            <TextField
                                                                value={field.value}
                                                                onChange={(e) => {
                                                                    const cleanedValue = removeSpecialCharactersAndAccents(e.target.value);
                                                                    field.onChange(cleanedValue);
                                                                }}
                                                                inputRef={field.ref}
                                                                label={t("text.issuingBody")}
                                                                placeholder={t("text.issuingBody") as string}
                                                                inputProps={{ maxLength: 100 }}
                                                                fullWidth
                                                                error={!!errors.document && !!errors.document.documentIssuer}
                                                                helperText={displayErrorMessage(errors.document?.documentIssuer?.message) as string}
                                                                variant='outlined'
                                                            />
                                                        )}
                                                    />
                                                </Grid>
                                                <Grid
                                                    item
                                                    xs={12}
                                                    sm={12}
                                                    md={hasDriverLicense === "2" ? 2 : 3}
                                                    lg={hasDriverLicense === "2" ? 2 : 3}
                                                    xl={hasDriverLicense === "2" ? 2 : 3}
                                                >
                                                    <CRMAddressStateOptions
                                                        name="document.documentStateIssuer"
                                                        label={t("text.issuingState")}
                                                        control={control}
                                                        states={addressStateOptions}
                                                        error={!!errors.document && !!errors.document.documentStateIssuer}
                                                    />
                                                    <FormHelperTextStyled>
                                                        {displayErrorMessage(errors.document?.documentStateIssuer?.message) as string}
                                                    </FormHelperTextStyled>
                                                </Grid>
                                                <Grid
                                                    item
                                                    xs={12}
                                                    sm={12}
                                                    md={3}
                                                    lg={3}
                                                    xl={3}
                                                >
                                                    <LocalizationProvider
                                                        dateAdapter={AdapterDateFns}
                                                        adapterLocale={languages[i18n.language as keyof typeof languages]}
                                                    >
                                                        <Controller
                                                            control={control}
                                                            name="document.documentDateOfIssue"
                                                            render={({ field }) => (
                                                                <DatePicker
                                                                    value={field.value}
                                                                    onChange={field.onChange}
                                                                    inputRef={field.ref}
                                                                    inputFormat="dd/MM/yyyy"
                                                                    renderInput={(params) =>
                                                                        <TextField
                                                                            {...params}
                                                                            inputProps={{
                                                                                ...params.inputProps,
                                                                                placeholder: t("text.issueDate")
                                                                            }}
                                                                            label={t("text.issueDate")}
                                                                            error={!!errors.document && !!errors.document.documentDateOfIssue}
                                                                            helperText={displayErrorMessage(errors.document?.documentDateOfIssue?.message) as string}
                                                                            fullWidth
                                                                            variant='outlined'
                                                                        />
                                                                    }
                                                                />
                                                            )}
                                                        />
                                                    </LocalizationProvider>
                                                </Grid>
                                                {(hasDriverLicense === "1" || documentTypeId !== "")
                                                    ? <>
                                                        <Grid
                                                            item
                                                            xs={12}
                                                            sm={12}
                                                            md={12}
                                                            lg={12}
                                                            xl={12}
                                                        >
                                                            <DocumentSubtitle
                                                                hasDriverLicense={hasDriverLicense}
                                                                typeOfDocument={documentTypeId}
                                                            />
                                                        </Grid>
                                                        <Grid
                                                            item
                                                            xs={12}
                                                            sm={12}
                                                            md={12}
                                                            lg={12}
                                                            xl={12}
                                                        >
                                                            <Card>
                                                                <DocumentHead
                                                                    hasDriverLicense={hasDriverLicense}
                                                                    typeOfDocument={documentTypeId}
                                                                />
                                                                <Document
                                                                    ref={proofRef}
                                                                    title={t("text.add")}
                                                                    register={register}
                                                                    getValues={getValues}
                                                                    name="proof"
                                                                    uploading={uploading}
                                                                    setUploading={setUploading}
                                                                />
                                                                <CardActions>
                                                                    <Typography variant="caption" display="block">{t("text.allowedFileTypesAndSize")}</Typography>
                                                                </CardActions>
                                                            </Card>
                                                            {errors.proof && <FormHelperTextStyled>{displayErrorMessage(errors.proof?.message) as string}</FormHelperTextStyled>}
                                                        </Grid>
                                                    </>
                                                    : null
                                                }
                                            </Grid>
                                            {fields.map((item, index) => {
                                                return (
                                                    <Grid
                                                        item
                                                        xs={12}
                                                        sm={12}
                                                        md={12}
                                                        lg={12}
                                                        xl={12}
                                                        key={index}
                                                        paddingTop={"1rem"}
                                                    >
                                                        <Grid
                                                            item
                                                            xs={12}
                                                            sm={12}
                                                            md={12}
                                                            lg={12}
                                                            xl={12}
                                                            paddingBottom={"1rem"}
                                                        >
                                                            <DocumentSubtitle
                                                                hasDriverLicense={hasDriverLicense}
                                                                typeOfDocument={documentTypeId}
                                                            />
                                                        </Grid>

                                                        <DocumentAdditional
                                                            index={index}
                                                            title={t("text.add")}
                                                            name={`documents.${index}.documentProof`}
                                                            register={register}
                                                            errors={errors}
                                                            remove={remove}
                                                            getValues={getValues}
                                                            hasDriverLicense={hasDriverLicense}
                                                            typeOfDocument={documentTypeId}
                                                            uploading={uploading}
                                                            setUploading={setUploading}
                                                        />
                                                    </Grid>
                                                );
                                            })}

                                            <Grid
                                                item
                                                xs={12}
                                                sm={12}
                                                md={12}
                                                lg={12}
                                                xl={12}
                                                paddingTop={"1rem"}

                                            >
                                                <Button
                                                    variant="outlined"
                                                    label={t("text.addDocument")}
                                                    startIcon={<Add />}
                                                    onClick={() => append({
                                                        id: "",
                                                        fileOriginalName: "",
                                                        fileSize: "",
                                                        fileType: "",
                                                        documentProof: "",
                                                    })}
                                                    disabled={cannotAddDocument()}
                                                />
                                            </Grid>

                                        </Grid>
                                        <Grid
                                            item
                                            xs={12}
                                            sm={12}
                                            md={12}
                                            lg={12}
                                            xl={12}
                                        >
                                            <Grid
                                                container
                                                spacing={1}
                                            >
                                                <Grid
                                                    item
                                                    xs={12}
                                                    sm={12}
                                                    md={12}
                                                    lg={12}
                                                    xl={12}
                                                >
                                                    <SubtitlePageForm>
                                                        {t("text.moreInformation")}
                                                    </SubtitlePageForm>
                                                </Grid>
                                                <Grid
                                                    item
                                                    xs={12}
                                                    sm={12}
                                                    md={12}
                                                    lg={12}
                                                    xl={12}
                                                >
                                                    <Divider />
                                                </Grid>
                                            </Grid>
                                        </Grid>

                                        <Grid
                                            item
                                            xs={12}
                                            sm={12}
                                            md={12}
                                            lg={12}
                                            xl={12}
                                        >
                                            <Grid
                                                container
                                                spacing={1}
                                            >
                                                <Grid
                                                    item
                                                    xs={12}
                                                    sm={12}
                                                    md={12}
                                                    lg={12}
                                                    xl={12}
                                                >
                                                    <FormLabelStyled>
                                                        {t("text.areYouUsPerson")}
                                                    </FormLabelStyled>
                                                </Grid>
                                                <Grid
                                                    item
                                                    xs={12}
                                                    sm={12}
                                                    md={12}
                                                    lg={12}
                                                    xl={12}
                                                >
                                                    <FormLabelInfoStyled>
                                                        {t("text.usPersonExplanation")}
                                                    </FormLabelInfoStyled>
                                                </Grid>
                                                <Grid
                                                    item
                                                    xs={12}
                                                    sm={12}
                                                    md={12}
                                                    lg={12}
                                                    xl={12}
                                                >
                                                    <FormControl>
                                                        <Controller
                                                            control={control}
                                                            name="usPerson"
                                                            render={({ field }) => {
                                                                return (
                                                                    <RadioGroup row {...field}>
                                                                        <FormControlLabelStyled
                                                                            value="1"
                                                                            control={<Radio />}
                                                                            label={t("text.yes")}
                                                                        />
                                                                        <FormControlLabelStyled
                                                                            value="2"
                                                                            control={<Radio />}
                                                                            label={t("text.no")}
                                                                        />
                                                                    </RadioGroup>
                                                                );
                                                            }}
                                                        />
                                                    </FormControl>
                                                    {errors.usPerson
                                                        ? <FormHelperTextStyled>{displayErrorMessage(errors.usPerson.message) as string}</FormHelperTextStyled>
                                                        : null
                                                    }
                                                </Grid>
                                                {usPerson === "1"
                                                    ? <Grid
                                                        item
                                                        xs={12}
                                                        sm={12}
                                                        md={12}
                                                        lg={12}
                                                        xl={12}
                                                    >
                                                        <Controller
                                                            control={control}
                                                            name="nif"
                                                            render={({ field }) => (
                                                                <TextField
                                                                    value={field.value}
                                                                    onChange={(e) => {
                                                                        const cleanedValue = removeSpecialCharactersAndAccents(e.target.value);
                                                                        field.onChange(cleanedValue);
                                                                    }}
                                                                    inputRef={field.ref}
                                                                    label={t("text.nif")}
                                                                    placeholder={t("text.nif") as string}
                                                                    inputProps={{ maxLength: 100 }}
                                                                    fullWidth
                                                                    error={!!errors.nif}
                                                                    helperText={displayErrorMessage(errors.nif?.message) as string}
                                                                    variant='outlined'
                                                                />
                                                            )}
                                                        />
                                                    </Grid>
                                                    : null
                                                }
                                            </Grid>
                                        </Grid>
                                        <Grid
                                            item
                                            xs={12}
                                            sm={12}
                                            md={12}
                                            lg={12}
                                            xl={12}
                                        >
                                            <Grid
                                                container
                                                spacing={1}
                                            >
                                                <Grid
                                                    item
                                                    xs={12}
                                                    sm={12}
                                                    md={12}
                                                    lg={12}
                                                    xl={12}
                                                >
                                                    <FormLabelStyled>
                                                        {t("text.isPepOrHasRelationshipWithPep")}
                                                    </FormLabelStyled>
                                                </Grid>
                                                <Grid
                                                    item
                                                    xs={12}
                                                    sm={12}
                                                    md={12}
                                                    lg={12}
                                                    xl={12}
                                                >
                                                    <FormLabelInfoStyled>
                                                        {t("text.pepExplanation")}
                                                    </FormLabelInfoStyled>
                                                </Grid>
                                                <Grid
                                                    item
                                                    xs={12}
                                                    sm={12}
                                                    md={12}
                                                    lg={12}
                                                    xl={12}
                                                >
                                                    <FormControl>
                                                        <Controller
                                                            control={control}
                                                            name="isPep"
                                                            render={({ field }) => {
                                                                return (
                                                                    <RadioGroup row {...field}>
                                                                        <FormControlLabelStyled
                                                                            value="120240000"
                                                                            control={<Radio />}
                                                                            label={t("text.pepYes")}
                                                                        />
                                                                        <FormControlLabelStyled
                                                                            value="120240001"
                                                                            control={<Radio />}
                                                                            label={t("text.pepNo")}
                                                                        />
                                                                    </RadioGroup>
                                                                );
                                                            }}
                                                        />
                                                    </FormControl>
                                                    {errors.isPep
                                                        ? <FormHelperTextStyled>{displayErrorMessage(errors.isPep.message) as string}</FormHelperTextStyled>
                                                        : null
                                                    }
                                                </Grid>
                                                {isPep === "120240000" && (
                                                    <Grid
                                                        item
                                                        xs={12}
                                                        sm={12}
                                                        md={12}
                                                        lg={12}
                                                        xl={12}
                                                    >
                                                        <Controller
                                                            control={control}
                                                            name="isPepExplain"
                                                            render={({ field }) => (
                                                                <TextField
                                                                    value={field.value}
                                                                    onChange={(e) => {
                                                                        const cleanedValue = removeSpecialCharactersAndAccents(e.target.value);
                                                                        field.onChange(cleanedValue);
                                                                    }}
                                                                    inputRef={field.ref}
                                                                    label={t("text.explain")}
                                                                    placeholder={t("text.explain") as string}
                                                                    fullWidth
                                                                    error={!!errors.isPepExplain}
                                                                    helperText={displayErrorMessage(errors?.isPepExplain?.message) as string}
                                                                    variant='outlined'
                                                                />
                                                            )}
                                                        />
                                                    </Grid>
                                                )}
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid
                                    item
                                    xs={12}
                                    sm={12}
                                    md={12}
                                    lg={12}
                                    xl={12}
                                >
                                    <Grid
                                        container
                                        spacing={2}
                                    >
                                        <Grid
                                            item
                                            xs={6}
                                            sm={6}
                                            md={6}
                                            lg={6}
                                            xl={6}
                                        >
                                            <Button
                                                variant="outlined"
                                                label={t("text.previousStep")}
                                                component={Link}
                                                to="/onboarding/contact-reason-register"
                                                disabled={submitting || uploading}
                                                fullWidth
                                            />
                                        </Grid>
                                        <Grid
                                            item
                                            xs={6}
                                            sm={6}
                                            md={6}
                                            lg={6}
                                            xl={6}
                                        >
                                            <LoadingButton
                                                type="submit"
                                                variant="contained"
                                                label={t("text.nextStep")}
                                                loading={submitting || uploading}
                                                fullWidth
                                            />
                                        </Grid>
                                        <Grid
                                            item
                                            xs={12}
                                            sm={12}
                                            md={12}
                                            lg={12}
                                            xl={12}
                                        >
                                            <Button
                                                variant="text"
                                                label={t("text.continueLater")}
                                                onClick={handleSignOut}
                                                disabled={submitting || uploading}
                                                fullWidth
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </React.Fragment>
                }
            </Grid>
        </form>
    );
}
