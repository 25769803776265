import {
    isValidCEP
} from "@brazilian-utils/brazilian-utils";
import { yupResolver } from '@hookform/resolvers/yup';
import { Search } from '@mui/icons-material';
import {
    Grid, Card, Typography, CardActions
} from '@mui/material';
import React, { useContext, useEffect, useRef, useState } from "react";
import { Controller, useForm, useWatch } from 'react-hook-form';
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { toast } from 'react-toastify';
import * as yup from "yup";
import { Button } from '../../../components/Button';
import { CRMAddressStateOptions } from "../../../components/CRMAddressStateOptions";
import { LoadingBox } from "../../../components/LoadingBox";
import { LoadingButton } from '../../../components/LoadingButton';
import { OnboardingStepper } from "../../../components/OnboardingStepper";
import { PageName } from '../../../components/PageName';
import { TextField } from '../../../components/TextField';
import { AuthContext } from "../../../contexts/auth";
import { findAddressByCep } from "../../../services/cepService";
import { getAddressPromise, saveAddress } from "../../../services/clientService";
import { getAddressStatePromise } from "../../../services/crmAddressState";
import { FormHelperTextStyled, SubSubtitlePageForm, CardHeaderTitleStyled } from "../../../styles/styles";
import { displayErrorMessage } from "../../../utils/errorMessage";
import * as masks from "../../../utils/masks";
import { getCRMCountriesPromise } from "../../../services/crmCountryService";
import { CRMCountryOptions } from "../../../components/CRMCountryOptions";
import { removeSpecialCharactersAndAccents } from "../../../utils/stringUtils";
import { toBase64 } from "../../../utils/fileUtils";
import Document from '../../../components/Document';
import { AddressState } from "../../../components/EditAddressModal/AddressState";

const validationSchema = yup.object({
    stepIndex: yup.string(),
    productId: yup.string(),
    estimatedValueId: yup.string(),
    country: yup.string().required(),
    zipCode: yup.string()
        .when("country", {
            is: "BR",
            then: yup.string().required()
                .transform(masks.cepMask.transform)
                .test("validateCep", (value) => {
                    return isValidCEP(value!)
                }),
            otherwise: yup.string().required()
        }),
    street: yup.string().required(),
    number: yup.string().required(),
    neighborhood: yup.string()
        .when("country", {
            is: "BR",
            then: yup.string().required()
        }),
    city: yup.string().required(),
    state: yup.string()
        .when("country", {
            is: "BR",
            then: yup.string().required()
        }),
    complement: yup.string(),
    addressProof: yup.mixed()
        .when("estimatedValueId", {
            is: "1",
            then: yup.mixed()
                .test("required", "validations.fileRequired", (value) => value.length > 0)
                .test("fileSize", "validations.fileSize", (value) => {
                    return value.length && value[0].size <= 20971520;
                })
                .test("fileType", "validations.fileType", (value) => {
                    return value.length && ["image/jpeg", "image/png", "image/jpg", "application/pdf", "image/bmp"].includes(value[0].type)
                }),
        }),
});

export function AddressTourism() {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { user } = useContext(AuthContext);
    const [loading, setLoading] = useState<boolean>(true);
    const [submitting, setSubmitting] = useState<boolean>(false);
    const [addressStateOptions, setAddressStateOptions] = useState<AddressState[]>([]);
    const [countryOptions, setCountryOptions] = useState([]);
    const [uploading, setUploading] = useState<boolean>(false);
    const addressProofRef = useRef<any>(null);

    const { control, handleSubmit, formState: { errors }, reset, getValues, setFocus, register } = useForm({
        resolver: yupResolver(validationSchema),
        defaultValues: {
            stepIndex: "",
            productId: "",
            estimatedValueId: "",
            country: "BR",
            zipCode: "",
            street: "",
            number: "",
            neighborhood: "",
            city: "",
            state: "",
            complement: "",
            addressProof: "",
        }
    });

    const estimatedValueId = useWatch({
        control,
        name: "estimatedValueId"
    });

    const country = useWatch({
        control,
        name: "country"
    });

    const countryChanged = () => {

        const values = {
            ...getValues(),
            zipCode: "",
            street: "",
            neighborhood: "",
            city: "",
            state: "",
            complement: "",
            number: "",
            addressProof: ""
        }

        if (addressProofRef.current !== null) {
            addressProofRef.current.removeFile();
        }

        setFocus("zipCode", { shouldSelect: true });
        reset(values);
    }

    useEffect(() => {
        async function fetchAddress() {
            try {

                const [addressStateOptions, countryOptions, address] = await Promise.all([
                    getAddressStatePromise(),
                    getCRMCountriesPromise(),
                    getAddressPromise()
                ]);

                setAddressStateOptions(addressStateOptions.data);
                setCountryOptions(countryOptions.data);

                const responseData = address.data;

                const addressProof = responseData?.addressProof;
                var _addressProof: any;
                if (addressProof?.id) {
                    _addressProof = new File(["addressProof"], addressProof.fileOriginalName, {
                        type: addressProof.fileType,
                    });
                    _addressProof.id! = addressProof.id;
                }

                const values = {
                    ...responseData,
                    country: responseData.country === "" ? "BR" : responseData.country,
                    zipCode: responseData.country === "BR" ? masks.cepMask.maskDefault(responseData.zipCode) : responseData.zipCode,
                    addressProof: _addressProof ? [].concat(_addressProof) : "",
                }

                reset(values);
                setLoading(false);
            } catch (error: any) {
                const errorCode = error.response?.data?.errorCode;
                if (errorCode === "12000") {
                    navigate('/onboarding/order');
                }
            }
        }

        fetchAddress();
    }, [navigate, reset, user.value.userId])

    const findByCep = async () => {
        const zipCode = masks.cepMask.unmask(getValues("zipCode"));
        if (zipCode === "" || zipCode.length !== 8) return;
        setSubmitting(true);
        try {
            const response = await findAddressByCep(zipCode);
            if (response.data.erro) {
                toast.error(`${t("validations.findAddress")}`);
                setSubmitting(false);
                setFocus("zipCode");
                return;
            }

            const state = addressStateOptions.find(s => s.state === response.data.uf);
            const values = {
                ...getValues(),
                street: removeSpecialCharactersAndAccents(response.data.logradouro),
                neighborhood: removeSpecialCharactersAndAccents(response.data.bairro),
                city: removeSpecialCharactersAndAccents(response.data.localidade),
                state: state?.id,
                complement: removeSpecialCharactersAndAccents(response.data.complemento),
                addressProof: ""
            }

            if (addressProofRef.current !== null) {
                addressProofRef.current.removeFile();
            }

            setFocus("number", { shouldSelect: true });
            reset(values);
        } catch (error) {
            console.log('error', error);
            toast.error(t("text.toast.zipCodeServiceUnavailable"));
        } finally {
            setSubmitting(false);
        }
    }

    const previous = async () => {
        navigate(`/dashboard/personal-data`);
    }

    const onSubmit = async (data: any) => {
        setSubmitting(true);
        try {
            const addressProof = data.addressProof[0];
            if (addressProof && !addressProof.id) {
                const result = await toBase64(addressProof);
                data = {
                    ...data,
                    addressProof: {
                        fileOriginalName: addressProof.name,
                        fileInBase64: result,
                        fileSize: addressProof.size,
                        fileType: addressProof.type,
                    }
                }
            } else {
                data = {
                    ...data,
                    addressProof: null
                }
            }

            let newData = {
                ...data,
                state: data.country === "BR" ? data.state : ""
            }

            await saveAddress(newData, false);
            navigate(
                estimatedValueId === "1"
                    ? `/dashboard/resume`
                    : `/dashboard/income-powerOfAttorney`
            )
        } catch (error: any) {
            const errorMessage = error.response?.data?.message || error.message;
            toast.error(errorMessage);
        } finally {
            setSubmitting(false);
        }
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Grid
                container
            >
                {loading
                    ? <LoadingBox />
                    : <React.Fragment>
                        <OnboardingStepper
                            estimatedValueId={estimatedValueId}
                            activeStep={2}
                        />
                        <Grid
                            item
                            xs={12}
                            sm={12}
                            md={12}
                            lg={12}
                            xl={12}
                            sx={{
                                background: 'var(--white)',
                                borderRadius: '4px',
                                padding: '2rem',
                                '@media(max-width: 899px)': {
                                    padding: '0rem',
                                }
                            }}
                        >
                            <Grid
                                container
                                spacing={3}
                            >
                                <Grid
                                    item
                                    xs={12}
                                    sm={12}
                                    md={12}
                                    lg={12}
                                    xl={12}
                                >
                                    <PageName
                                        title={t("text.address")}
                                        info={t("text.foreignAddressesCantBeAdded")}
                                    />
                                </Grid>
                                <Grid
                                    item
                                    xs={12}
                                    sm={12}
                                    md={12}
                                    lg={12}
                                    xl={12}
                                >
                                    <Grid
                                        container
                                        spacing={2}
                                    >

                                        <Grid
                                            item
                                            xs={12}
                                            sm={12}
                                            md={12}
                                            lg={12}
                                            xl={12}
                                        >
                                            <CRMCountryOptions
                                                name="country"
                                                control={control}
                                                countries={countryOptions}
                                                error={!!errors.country}
                                                countryChanged={countryChanged}
                                            />
                                            <FormHelperTextStyled>
                                                {displayErrorMessage(errors.country?.message) as string}
                                            </FormHelperTextStyled>
                                        </Grid>

                                        <Grid
                                            item
                                            xs={6}
                                            sm={6}
                                            md={9}
                                            lg={2}
                                            xl={2}
                                        >
                                            <Controller
                                                control={control}
                                                name="zipCode"
                                                render={({ field }) => (
                                                    <TextField
                                                        value={field.value}
                                                        onChange={(e) => {
                                                            e.persist();
                                                            if (country === "BR") {
                                                                masks.cepMask.onChange(e);
                                                                field.onChange(e);
                                                            } else {
                                                                const cleanedValue = removeSpecialCharactersAndAccents(e.target.value);
                                                                field.onChange(cleanedValue);
                                                            };
                                                        }}
                                                        inputRef={field.ref}
                                                        label={t("text.zipCode")}
                                                        placeholder={t("text.zipCode") as string}
                                                        inputProps={{ maxLength: 20 }}
                                                        fullWidth
                                                        error={!!errors.zipCode}
                                                        helperText={displayErrorMessage(errors?.zipCode?.message) as string}
                                                        variant='outlined'
                                                    />
                                                )}
                                            />
                                        </Grid>
                                        {country === "BR" ?
                                            <Grid
                                                item
                                                xs={6}
                                                sm={6}
                                                md={3}
                                                lg={2}
                                                xl={2}
                                                sx={{
                                                    marginTop: '1.3rem',
                                                }}
                                            >
                                                <Button
                                                    type="button"
                                                    label={t("text.search")}
                                                    variant="outlined"
                                                    startIcon={<Search />}
                                                    onClick={() => findByCep()}
                                                    disabled={submitting}
                                                    fullWidth
                                                />
                                            </Grid> : null}
                                        <Grid
                                            item
                                            xs={12}
                                            sm={12}
                                            md={9}
                                            lg={country === "BR" ? 5 : 7}
                                            xl={country === "BR" ? 5 : 7}
                                        >
                                            <Controller
                                                control={control}
                                                name="street"
                                                render={({ field }) => (
                                                    <TextField
                                                        value={field.value}
                                                        onChange={(e) => {
                                                            const cleanedValue = removeSpecialCharactersAndAccents(e.target.value);
                                                            field.onChange(cleanedValue);
                                                        }}
                                                        inputRef={field.ref}
                                                        label={t("text.streetName")}
                                                        placeholder={t("text.streetName") as string}
                                                        inputProps={{ maxLength: 250 }}
                                                        fullWidth
                                                        error={!!errors.street}
                                                        helperText={displayErrorMessage(errors?.street?.message) as string}
                                                        variant='outlined'
                                                    />
                                                )}
                                            />
                                        </Grid>
                                        <Grid
                                            item
                                            xs={12}
                                            sm={12}
                                            md={3}
                                            lg={3}
                                            xl={3}
                                        >
                                            <Controller
                                                control={control}
                                                name="number"
                                                render={({ field }) => (
                                                    <TextField
                                                        value={field.value}
                                                        onChange={(e) => {
                                                            const cleanedValue = removeSpecialCharactersAndAccents(e.target.value);
                                                            field.onChange(cleanedValue);
                                                        }}
                                                        inputRef={field.ref}
                                                        label={t("text.number")}
                                                        placeholder={t("text.number") as string}
                                                        inputProps={{ maxLength: 10 }}
                                                        fullWidth
                                                        error={!!errors.number}
                                                        helperText={displayErrorMessage(errors?.number?.message) as string}
                                                        variant='outlined'
                                                    />
                                                )}
                                            />
                                        </Grid>
                                        <Grid
                                            item
                                            xs={12}
                                            sm={12}
                                            md={country === "BR" ? 6 : 12}
                                            lg={country === "BR" ? 6 : 12}
                                            xl={country === "BR" ? 6 : 12}
                                        >
                                            <Controller
                                                control={control}
                                                name="complement"
                                                render={({ field }) => (
                                                    <TextField
                                                        value={field.value}
                                                        onChange={(e) => {
                                                            const cleanedValue = removeSpecialCharactersAndAccents(e.target.value);
                                                            field.onChange(cleanedValue);
                                                        }}
                                                        inputRef={field.ref}
                                                        label={t("text.complement")}
                                                        placeholder={t("text.complement") as string}
                                                        inputProps={{ maxLength: 100 }}
                                                        fullWidth
                                                        variant='outlined'
                                                    />
                                                )}
                                            />
                                        </Grid>
                                        {country === "BR" ?
                                            <Grid
                                                item
                                                xs={12}
                                                sm={12}
                                                md={6}
                                                lg={6}
                                                xl={6}
                                            >
                                                <Controller
                                                    control={control}
                                                    name="neighborhood"
                                                    render={({ field }) => (
                                                        <TextField
                                                            value={field.value}
                                                            onChange={(e) => {
                                                                const cleanedValue = removeSpecialCharactersAndAccents(e.target.value);
                                                                field.onChange(cleanedValue);
                                                            }}
                                                            inputRef={field.ref}
                                                            label={t("text.neighborhood")}
                                                            placeholder={t("text.neighborhood") as string}
                                                            inputProps={{ maxLength: 100 }}
                                                            fullWidth
                                                            error={!!errors.neighborhood}
                                                            helperText={displayErrorMessage(errors?.neighborhood?.message) as string}
                                                            variant='outlined'
                                                        />
                                                    )}
                                                />
                                            </Grid> : null
                                        }
                                        <Grid
                                            item
                                            xs={12}
                                            sm={12}
                                            md={country === "BR" ? 9 : 12}
                                            lg={country === "BR" ? 9 : 12}
                                            xl={country === "BR" ? 9 : 12}
                                        >
                                            <Controller
                                                control={control}
                                                name="city"
                                                render={({ field }) => (
                                                    <TextField
                                                        value={field.value}
                                                        onChange={(e) => {
                                                            const cleanedValue = removeSpecialCharactersAndAccents(e.target.value);
                                                            field.onChange(cleanedValue);
                                                        }}
                                                        inputRef={field.ref}
                                                        label={t("text.city")}
                                                        placeholder={t("text.city") as string}
                                                        inputProps={{ maxLength: 80 }}
                                                        fullWidth
                                                        error={!!errors.city}
                                                        helperText={displayErrorMessage(errors?.city?.message) as string}
                                                        variant='outlined'
                                                    />
                                                )}
                                            />
                                        </Grid>
                                        {country === "BR" ?
                                            <Grid
                                                item
                                                xs={12}
                                                sm={12}
                                                md={3}
                                                lg={3}
                                                xl={3}
                                            >
                                                <CRMAddressStateOptions
                                                    name="state"
                                                    label={t("text.uf")}
                                                    control={control}
                                                    states={addressStateOptions}
                                                    error={!!errors.state}
                                                />
                                                <FormHelperTextStyled>
                                                    {displayErrorMessage(errors.state?.message) as string}
                                                </FormHelperTextStyled>
                                            </Grid> : null}
                                    </Grid>
                                </Grid>
                                <Grid
                                    item
                                    xs={12}
                                    sm={12}
                                    md={12}
                                    lg={12}
                                    xl={12}
                                >
                                    <Grid
                                        container
                                        spacing={2}
                                    >
                                        {estimatedValueId === "1" &&
                                            <React.Fragment>

                                                <Grid
                                                    item
                                                    xs={12}
                                                    sm={12}
                                                    md={12}
                                                    lg={12}
                                                    xl={12}
                                                >
                                                    <SubSubtitlePageForm>
                                                        {t("text.attachDocument")}
                                                    </SubSubtitlePageForm>
                                                </Grid>
                                                <Grid
                                                    item
                                                    xs={12}
                                                    sm={12}
                                                    md={12}
                                                    lg={12}
                                                    xl={12}
                                                >
                                                    <Card>
                                                        <CardHeaderTitleStyled
                                                            title={t("text.addressProof")}
                                                            subheader={t("text.proofOfAddress90Days")}
                                                        />
                                                        <Document
                                                            ref={addressProofRef}
                                                            title={t("text.add")}
                                                            register={register}
                                                            getValues={getValues}
                                                            name="addressProof"
                                                            uploading={uploading}
                                                            setUploading={setUploading}
                                                        />
                                                        <CardActions>
                                                            <Typography variant="caption" display="block">
                                                                {t("text.allowedFileTypesAndSize")}
                                                            </Typography>
                                                        </CardActions>
                                                    </Card>
                                                    {errors.addressProof && <FormHelperTextStyled>{displayErrorMessage(errors.addressProof?.message) as string}</FormHelperTextStyled>}
                                                </Grid>
                                            </React.Fragment>
                                        }
                                    </Grid>
                                </Grid>
                                <Grid
                                    item
                                    xs={12}
                                    sm={12}
                                    md={12}
                                    lg={12}
                                    xl={12}
                                >
                                    <Grid
                                        container
                                        spacing={2}
                                    >
                                        <Grid
                                            item
                                            xs={6}
                                            sm={6}
                                            md={6}
                                            lg={6}
                                            xl={6}
                                        >
                                            <Button
                                                variant="outlined"
                                                label={t("text.previousStep")}
                                                onClick={previous}
                                                disabled={submitting}
                                                fullWidth
                                            />
                                        </Grid>
                                        <Grid
                                            item
                                            xs={6}
                                            sm={6}
                                            md={6}
                                            lg={6}
                                            xl={6}
                                        >
                                            <LoadingButton
                                                type="submit"
                                                variant="contained"
                                                label={t("text.nextStep")}
                                                loading={submitting}
                                                fullWidth
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </React.Fragment>
                }
            </Grid>
        </form >
    );
}
