import { isValidCNPJ, isValidCPF, isValidEmail } from "@brazilian-utils/brazilian-utils";
import { yupResolver } from "@hookform/resolvers/yup";
import {
    Alert,
    FormControl,
    Grid,
    IconButton,
    InputAdornment,
    Radio,
    RadioGroup
} from "@mui/material";
import React, { useContext, useState } from "react";
import { Controller, useForm, useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";
import * as yup from "yup";
import { TextField } from "../../components/TextField";
import { AuthContext } from "../../contexts/auth";
import {
    FormControlLabelStyled, FormLabelStyled,
    FormTitle,
    SpanStyled
} from "../../styles/styles";
import { displayErrorMessage } from "../../utils/errorMessage";
import * as masks from "../../utils/masks";

import { ArrowBack, Visibility, VisibilityOff } from "@mui/icons-material";
import { Link } from "react-router-dom";
import { LoadingButton } from "../../components/LoadingButton";
import TextButton from "../../components/TextButton";
import { FormDescription } from '../../styles/styles';
import { stripToEmpty } from "../../utils/stringUtils";

const validationSchema = yup.object({
    order: yup.string(),
    type: yup.string().required(),
    document: yup.string()
        .when('type', {
            is: "2",
            then: yup.string()
                .transform(masks.cpfCnpjMask.transform)
                .required()
                .test(
                    {
                        name: "validateCNPJ",
                        test: function (value) {
                            if (isValidCNPJ(value!)) {
                                return true;
                            }
                            return this.createError({
                                path: 'document',
                            })
                        }
                    }
                ),
            otherwise: yup.string()
                .transform(masks.cpfCnpjMask.transform)
                .required()
                .test(
                    {
                        name: "validateCPF",
                        test: function (value) {
                            if (isValidCPF(value!)) {
                                return true;
                            }
                            return this.createError({
                                path: 'document',
                            })
                        }
                    }
                )
        }),
    email: yup.string()
        .required()
        .min(10)
        .transform((curr, orig) => stripToEmpty(curr))
        .test("validateEmail", (value) => isValidEmail(stripToEmpty(value))),
    password: yup.string()
        .min(16)
        .max(32)
        .matches(/[a-z]/, "validations.lowercase")
        .matches(/[A-Z]/, "validations.uppercase")
        .matches(/[0-9]/, "validations.number")
        .matches(/[^\w]/, "validations.specialCharacter")
        .test(
            "no-sequential-repeated-characters",
            "validations.noSequentialRepeatedCharacters",
            (value) => {
                if (!value) return false;
                const seqRegex = /(.)\1{2,}/;
                return !seqRegex.test(value);
            }
        )
        .test(
            "no-predictable-values",
            "validations.noPredictableValues",
            (value) => {
                if (!value) return false;
                const lowerValue = value.toLowerCase();
                const predictableValues = [
                    "password",
                    "pass",
                    "moneycorp",
                    "fxmoneyform",
                    "fxmoneyservice",
                ];
                return !predictableValues.some((pred) => lowerValue.includes(pred));
            }
        )
        .test(
            "no-email-pattern",
            "validations.noEmailPattern",
            (value) => {
                if (!value) return false;
                const emailRegex = /[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}/;
                return !emailRegex.test(value);
            }
        )
        .required(),
    passwordConfirmation: yup.string()
        .min(16)
        .max(32)
        .matches(/[a-z]/, "validations.lowercase")
        .matches(/[A-Z]/, "validations.uppercase")
        .matches(/[0-9]/, "validations.number")
        .matches(/[^\w]/, "validations.specialCharacter")
        .test(
            "no-sequential-repeated-characters",
            "validations.noSequentialRepeatedCharacters",
            (value) => {
                if (!value) return false;
                const seqRegex = /(.)\1{2,}/;
                return !seqRegex.test(value);
            }
        )
        .test(
            "no-predictable-values",
            "validations.noPredictableValues",
            (value) => {
                if (!value) return false;
                const lowerValue = value.toLowerCase();
                const predictableValues = [
                    "password",
                    "pass",
                    "moneycorp",
                    "fxmoneyform",
                    "fxmoneyservice",
                ];
                return !predictableValues.some((pred) => lowerValue.includes(pred));
            }
        )
        .test(
            "no-email-pattern",
            "validations.noEmailPattern",
            (value) => {
                if (!value) return false;
                const emailRegex = /[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}/;
                return !emailRegex.test(value);
            }
        )
        .required()
        .oneOf([yup.ref("password")], "validations.passwordsDoNotMatch"),
});

export function CreateAccount() {
    const { t } = useTranslation();
    const { user, handleSignUp } = useContext(AuthContext);
    const [showPassword, setShowPassword] = useState<boolean>(false);

    const { control, handleSubmit, formState: { errors }, getFieldState } = useForm({
        mode: "onChange",
        resolver: yupResolver(validationSchema),
        defaultValues: {
            order: "",
            type: "1",
            document: "",
            email: "",
            password: "",
            passwordConfirmation: "",
        }
    })

    const passwordFieldState = getFieldState("password");
    const passwordConfirmationFieldState = getFieldState("passwordConfirmation");

    const type = useWatch({
        control,
        name: "type",
    });

    const onSubmit = async (data: any) => {

        const url = new URL(window.location.href);
        const orderParam = url.searchParams.get('order');

        var values = data;

        if (orderParam) {
            values = {
                ...data,
                order: orderParam
            }
        }

        await handleSignUp(values);
    };

    const getPasswordBorderColor = () => {
        return passwordFieldState.error === undefined && passwordFieldState.isDirty
            ? "green"
            : ""
    };

    const getPasswordConfirmationBorderColor = () => {
        return passwordConfirmationFieldState.error === undefined && passwordConfirmationFieldState.isDirty
            ? "green"
            : ""
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <fieldset disabled={user.authenticating} style={{ border: 0 }}>
                <Grid
                    item
                    xs={12}
                    sm={8}
                    md={4}
                    lg={4}
                    xl={4}
                    sx={{ p: 4 }}
                >
                    <Grid
                        container
                        spacing={2}
                    >
                        <Grid
                            item
                            xs={6}
                            sm={6}
                            md={6}
                            lg={6}
                            xl={6}
                            sx={{
                                textTransform: 'uppercase'
                            }}
                        >
                            <TextButton
                                label={t("text.back")}
                                startIcon={<ArrowBack />}
                                component={Link}
                                to="/" />
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            sm={12}
                            md={12}
                            lg={12}
                            xl={12}
                            sx={{
                                textAlign: "left",
                                pb: 1,
                            }}
                        >
                            {type === "1" ?
                                <>
                                    <FormTitle>
                                        {t("text.fillinTheRegistration")}
                                    </FormTitle>
                                    <FormDescription>
                                        {t("text.makeInternationalPayments")}
                                    </FormDescription>
                                </> :
                                <>
                                    <FormTitle>
                                        {t("text.fillinTheRegistration")}
                                    </FormTitle>
                                    <FormDescription>
                                        {t("text.andYourCompanyWillHave")}
                                    </FormDescription>
                                </>
                            }
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            sm={12}
                            md={12}
                            lg={12}
                            xl={12}
                        >
                            <FormControl>
                                <FormLabelStyled>
                                    {t("text.selectAnOption")}
                                </FormLabelStyled>
                                <Controller
                                    control={control}
                                    name="type"
                                    render={({ field }) => {
                                        return (
                                            <RadioGroup row {...field}>
                                                <FormControlLabelStyled
                                                    value="1"
                                                    control={<Radio />}
                                                    label={t("text.private")}
                                                />
                                                <FormControlLabelStyled
                                                    value="2"
                                                    control={<Radio />}
                                                    label={t("text.corporate")}
                                                />
                                            </RadioGroup>
                                        );
                                    }}
                                />
                            </FormControl>
                        </Grid>
                        {type === "2"
                            ? <Grid
                                item
                                xs={12}
                                sm={12}
                                md={12}
                                lg={12}
                                xl={12}
                            >
                                <Controller
                                    control={control}
                                    name="document"
                                    render={({ field }) => (
                                        <TextField
                                            id="document"
                                            fullWidth
                                            value={field.value}
                                            onChange={(e) => {
                                                e.persist();
                                                masks.cnpjMask.onChange(e);
                                                field.onChange(e);
                                            }}
                                            inputRef={field.ref}
                                            label={t("text.cnpj")}
                                            placeholder={t("text.cnpj") as string}
                                            error={!!errors.document}
                                            helperText={displayErrorMessage(errors?.document?.message) as string}
                                            variant="outlined"
                                        />
                                    )}
                                />
                            </Grid>
                            : <Grid
                                item
                                xs={12}
                                sm={12}
                                md={12}
                                lg={12}
                                xl={12}
                            >
                                <Controller
                                    control={control}
                                    name="document"
                                    render={({ field }) => (
                                        <TextField
                                            id="document"
                                            fullWidth
                                            value={field.value}
                                            onChange={(e) => {
                                                e.persist();
                                                masks.cpfMask.onChange(e);
                                                field.onChange(e);
                                            }}
                                            inputRef={field.ref}
                                            label={t("text.cpf")}
                                            placeholder={t("text.cpf") as string}
                                            error={!!errors.document}
                                            helperText={displayErrorMessage(errors?.document?.message) as string}
                                            variant="outlined"
                                        />
                                    )}
                                />
                            </Grid>
                        }
                        <Grid
                            item
                            xs={12}
                            sm={12}
                            md={12}
                            lg={12}
                            xl={12}
                        >
                            <Controller
                                control={control}
                                name="email"
                                render={({ field }) => (
                                    <TextField
                                        value={field.value}
                                        onChange={field.onChange}
                                        inputRef={field.ref}
                                        label={t("text.email")}
                                        placeholder={t("text.email") as string}
                                        inputProps={{ maxLength: 100 }}
                                        fullWidth
                                        type="email"
                                        error={!!errors.email}
                                        helperText={displayErrorMessage(errors?.email?.message) as string}
                                        variant="outlined"
                                    />
                                )}
                            />
                        </Grid>
                        <Grid
                            item
                            sm={12}
                            md={12}
                            lg={12}
                            xl={12}
                        >
                            <Alert severity="info">
                                {t("text.passwordHint")}
                            </Alert>
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            sm={12}
                            md={12}
                            lg={12}
                            xl={12}
                        >
                            <Controller
                                control={control}
                                name="password"
                                render={({ field }) => (
                                    <TextField
                                        type={showPassword ? "text" : "password"}
                                        bordercolor={getPasswordBorderColor()}
                                        value={field.value}
                                        onChange={field.onChange}
                                        inputRef={field.ref}
                                        label={t("text.password")}
                                        placeholder={t("text.password") as string}
                                        error={!!errors.password}
                                        fullWidth
                                        helperText={displayErrorMessage(errors?.password?.message) as string}
                                        InputProps={{
                                            endAdornment:
                                                <InputAdornment
                                                    position="end"
                                                >
                                                    <IconButton
                                                        onClick={() => setShowPassword(!showPassword)}
                                                    >
                                                        {showPassword ? <Visibility /> : <VisibilityOff />}
                                                    </IconButton>
                                                </InputAdornment>
                                        }}
                                        variant="outlined"
                                    />
                                )}
                            />
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            sm={12}
                            md={12}
                            lg={12}
                            xl={12}
                        >
                            <Controller
                                control={control}
                                name="passwordConfirmation"
                                render={({ field }) => (
                                    <TextField
                                        type="password"
                                        bordercolor={getPasswordConfirmationBorderColor()}
                                        value={field.value}
                                        onChange={field.onChange}
                                        inputRef={field.ref}
                                        label={t("text.passwordConfirmation")}
                                        placeholder={t("text.passwordConfirmation") as string}
                                        error={!!errors.passwordConfirmation}
                                        fullWidth
                                        helperText={displayErrorMessage(errors?.passwordConfirmation?.message) as string}
                                        variant="outlined"
                                    />
                                )}
                            />
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            sm={12}
                            md={12}
                            lg={12}
                            xl={12}
                        >
                            <LoadingButton
                                type="submit"
                                variant="contained"
                                label={t("text.createAccount")}
                                fullWidth
                                loading={user.authenticating}
                            />
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            sm={12}
                            md={12}
                            lg={12}
                            xl={12}
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                gap: '.25rem',
                                justifyContent: 'center',
                            }}
                        >
                            <SpanStyled>
                                {t("text.alreadyHaveAnAccount")}
                            </SpanStyled>
                            <TextButton
                                label={t("text.clickHere")}
                                component={Link}
                                to="/"
                                disabled={user.authenticating}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </fieldset>
        </form >
    );
}
