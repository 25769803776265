import { yupResolver } from '@hookform/resolvers/yup';
import {
    Card, CardActions, Grid,
    MenuItem, Typography
} from '@mui/material';
import { Add } from '@mui/icons-material';
import React, { useContext, useEffect, useState } from "react";
import { Controller, useForm, useFieldArray, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as yup from "yup";
import { Button } from '../../../components/Button';
import Document from '../../../components/Document';
import { LoadingBox } from '../../../components/LoadingBox';
import { LoadingButton } from '../../../components/LoadingButton';
import { OnboardingStepper } from '../../../components/OnboardingStepper';
import { PageName } from '../../../components/PageName';
import { Select } from '../../../components/Select';
import { AuthContext } from "../../../contexts/auth";
import { getIncomeAndPowerOfAttorney, saveIncomeAndPowerOfAttorney } from "../../../services/attorneyService";
import {
    CardHeaderTitleStyled, FormHelperTextStyled, SubSubtitlePageForm
} from '../../../styles/styles';
import { displayErrorMessage } from "../../../utils/errorMessage";
import { toBase64 } from "../../../utils/fileUtils";
import { DocumentSubtitleAdditional } from '../../../components/DocumentSubtitleAdditional';
import DocumentAdditional from '../../../components/DocumentAdditional';
import { IIncomeAndPowerOfAttorney } from '../../../interfaces/income';

const validationSchema = yup.object({
    stepIndex: yup.string(),
    estimatedValueId: yup.string(),
    incomeId: yup.string()
        .when("estimatedValueId", {
            is: "2",
            then: yup.string()
                .required()
        }),
    patrimonyId: yup.string()
        .when("estimatedValueId", {
            is: "2",
            then: yup.string()
                .required()
        }),
    incomeTaxProof: yup.mixed()
        .when("estimatedValueId", {
            is: "2",
            then: yup.mixed()
                .test("required", "validations.fileRequired", (value) => value.length > 0)
                .test("fileSize", "validations.fileSize", (value) => {
                    return value.length && value[0].size <= 20971520;
                })
                .test("fileType", "validations.fileType", (value) => {
                    return value.length && ["image/jpeg", "image/png", "image/jpg", "application/pdf", "image/bmp"].includes(value[0].type)
                }),
        }),
    documents: yup.array()
        .of(
            yup.object().shape({
                id: yup.string(),
                documentProof: yup.mixed()
                    .test("required", "validations.fileRequired", (value) => value.length > 0)
                    .test("fileSize", "validations.fileSize", (value) => {
                        return value.length && value[0].size <= 20971520;
                    })
                    .test("fileType", "validations.fileType", (value) => {
                        return value.length && ["image/jpeg", "image/png", "image/jpg", "application/pdf", "image/bmp"].includes(value[0].type)
                    }),
            })
        ),
});

export function IncomeAndPowerOfAttorney() {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [submitting, setSubmitting] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(true);
    const { user } = useContext(AuthContext);
    const [uploading, setUploading] = useState<boolean>(false);

    const { control, handleSubmit, formState: { errors }, reset, register, getValues } = useForm<IIncomeAndPowerOfAttorney>({
        resolver: yupResolver(validationSchema),
        defaultValues: {
            stepIndex: "",
            productId: "",
            estimatedValueId: "",
            incomeId: "",
            patrimonyId: "",
            incomeTaxProof: [],
        }
    });

    const { fields, append, remove } = useFieldArray<any, string, "keyName">({
        control,
        name: "documents",
        keyName: "keyName",
    });

    const estimatedValueId = useWatch({
        control,
        name: "estimatedValueId"
    });

    useEffect(() => {
        async function fetchIncomeAndPowerOfAttorney() {
            try {
                const response = await getIncomeAndPowerOfAttorney();
                const responseData = response.data;

                const document = responseData?.document;
                var _incomeTaxProof: any;
                if (document?.id) {
                    _incomeTaxProof = new File(["incomeTaxProof"], document.fileOriginalName, {
                        type: document.fileType,
                    });
                    _incomeTaxProof.id = document.id;
                }

                let values = {
                    ...responseData,
                    incomeTaxProof: _incomeTaxProof ? [].concat(_incomeTaxProof) : "",
                }

                if (responseData?.documents) {
                    let documents: any[] = [];
                    for (let i = 0; i < responseData.documents.length; i = i + 1) {

                        var _documentProof: any = new File(["documentProof"], responseData.documents[i].fileOriginalName, {
                            type: responseData.documents[i].fileType,
                        });
                        _documentProof.id = responseData.documents[i].id;

                        documents.push({
                            id: responseData.documents[i].id,
                            documentTypeId: responseData.documents[i].id,
                            documentProof: _documentProof ? [].concat(_documentProof) : "",
                        });
                    }

                    values = {
                        ...values,
                        documents: documents
                    };
                }

                reset(values);
                setLoading(false);
            } catch (error: any) {
                const errorCode = error.response?.data?.errorCode;
                if (errorCode === "12000") {
                    navigate('/onboarding/order');
                }
            }
        }

        fetchIncomeAndPowerOfAttorney();
    }, [navigate, reset, user.value.userId]);

    const previous = async () => {
        navigate(`/dashboard/address`);
    }

    const onSubmit = async (data: IIncomeAndPowerOfAttorney) => {
        try {
            setSubmitting(true);
            const incomeTaxProof: any = data.incomeTaxProof![0];
            if (incomeTaxProof && !incomeTaxProof.id) {
                const result = await toBase64(incomeTaxProof);
                data = {
                    ...data,
                    document: {
                        fileOriginalName: incomeTaxProof.name,
                        fileInBase64: result,
                        fileSize: incomeTaxProof.size,
                        fileType: incomeTaxProof.type,
                    }
                }
            }

            if (data?.documents) {
                let documentProofs: any[] = [];
                for (let i = 0; i < data.documents.length; i = i + 1) {
                    let documentProof = data.documents[i].documentProof[0];
                    const result = !documentProof.id ? await toBase64(documentProof) : null;
                    documentProofs.push({
                        id: documentProof.id,
                        fileOriginalName: documentProof.name,
                        fileInBase64: result,
                        fileSize: documentProof.size,
                        fileType: documentProof.type
                    });
                }

                data = {
                    ...data,
                    documents: documentProofs
                }
            }

            await saveIncomeAndPowerOfAttorney(data, false)
            navigate(`/dashboard/resume`);
        } catch (error: any) {
            const errorMessage = error.response?.data?.message || error.message;
            toast.error(errorMessage);
        } finally {
            setSubmitting(false);
        }
    }

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Grid
                container
            >
                {loading
                    ? <LoadingBox />
                    : <React.Fragment>
                        <OnboardingStepper
                            activeStep={3}
                            operationEstimatedValue={estimatedValueId}
                        />
                        <Grid
                            item
                            xs={12}
                            sm={12}
                            md={12}
                            lg={12}
                            xl={12}
                            sx={{
                                background: 'var(--white)',
                                borderRadius: '4px',
                                padding: '2rem',
                                '@media(max-width: 899px)': {
                                    padding: '0rem',
                                }
                            }}
                        >
                            <Grid
                                container
                                spacing={3}
                            >
                                <Grid
                                    item
                                    xs={12}
                                    sm={12}
                                    md={12}
                                    lg={12}
                                    xl={12}
                                >
                                    <PageName
                                        title={t("text.income")}
                                        info={t("text.toProceedWithTheRegistrationPleaseFillInTheInformationBelow")}
                                    />
                                </Grid>
                                <Grid
                                    item
                                    xs={12}
                                    sm={12}
                                    md={12}
                                    lg={12}
                                    xl={12}
                                >
                                    <Grid
                                        container
                                        spacing={2}
                                    >
                                        {estimatedValueId === "2" &&
                                            <React.Fragment>
                                                <Grid
                                                    item
                                                    xs={12}
                                                    sm={12}
                                                    md={6}
                                                    lg={6}
                                                    xl={6}
                                                >
                                                    <Controller
                                                        control={control}
                                                        name="incomeId"
                                                        render={({ field }) => (
                                                            <Select
                                                                value={field.value}
                                                                onChange={field.onChange}
                                                                inputRef={field.ref}
                                                                label={t("text.monthlyIncome") as string}
                                                                error={!!errors.incomeId}
                                                                displayEmpty
                                                            >
                                                                <MenuItem value="">
                                                                    {t("text.select")}
                                                                </MenuItem>
                                                                <MenuItem value="120240000">
                                                                    {t("text.until3Thousand")}
                                                                </MenuItem>
                                                                <MenuItem value="120240001">
                                                                    {t("text.from3to5Thousand")}
                                                                </MenuItem>
                                                                <MenuItem value="120240002">
                                                                    {t("text.from5to10Thousand")}
                                                                </MenuItem>
                                                                <MenuItem value="120240003">
                                                                    {t("text.from10to20Thousand")}
                                                                </MenuItem>
                                                                <MenuItem value="120240004">
                                                                    {t("text.over20Thousand")}
                                                                </MenuItem>
                                                            </Select>
                                                        )}
                                                    />
                                                    <FormHelperTextStyled>
                                                        {displayErrorMessage(errors.incomeId?.message) as string}
                                                    </FormHelperTextStyled>
                                                </Grid>
                                                <Grid
                                                    item
                                                    xs={12}
                                                    sm={12}
                                                    md={6}
                                                    lg={6}
                                                    xl={6}
                                                >
                                                    <Controller
                                                        control={control}
                                                        name="patrimonyId"
                                                        render={({ field }) => (
                                                            <Select
                                                                value={field.value}
                                                                onChange={field.onChange}
                                                                inputRef={field.ref}
                                                                label={t("text.declaredPatrimony") as string}
                                                                error={!!errors.patrimonyId}
                                                                displayEmpty
                                                            >
                                                                <MenuItem value="">
                                                                    {t("text.select")}
                                                                </MenuItem>
                                                                <MenuItem value="120240000">
                                                                    {t("text.until100Thousand")}
                                                                </MenuItem>
                                                                <MenuItem value="120240001">
                                                                    {t("text.from100to300Thousand")}
                                                                </MenuItem>
                                                                <MenuItem value="120240002">
                                                                    {t("text.from300to500Thousand")}
                                                                </MenuItem>
                                                                <MenuItem value="120240003">
                                                                    {t("text.from500to1Million")}
                                                                </MenuItem>
                                                                <MenuItem value="120240004">
                                                                    {t("text.over1Million")}
                                                                </MenuItem>
                                                            </Select>
                                                        )}
                                                    />
                                                    <FormHelperTextStyled>
                                                        {displayErrorMessage(errors.patrimonyId?.message) as string}
                                                    </FormHelperTextStyled>
                                                </Grid>
                                                <Grid
                                                    item
                                                    xs={12}
                                                    sm={12}
                                                    md={12}
                                                    lg={12}
                                                    xl={12}
                                                >
                                                    <SubSubtitlePageForm>
                                                        {t("text.attachDocument")}
                                                    </SubSubtitlePageForm>
                                                </Grid>
                                                <Grid
                                                    item
                                                    xs={12}
                                                    sm={12}
                                                    md={12}
                                                    lg={12}
                                                    xl={12}
                                                >
                                                    <Card>
                                                        <CardHeaderTitleStyled
                                                            title={t("text.incomeTax")}
                                                            subheader={t("text.sendCompleteDocument")}
                                                        />
                                                        <Document
                                                            title={t("text.add")}
                                                            register={register}
                                                            getValues={getValues}
                                                            name="incomeTaxProof"
                                                            uploading={uploading}
                                                            setUploading={setUploading}
                                                        />
                                                        <CardActions>
                                                            <Typography variant="caption" display="block">
                                                                {t("text.allowedFileTypesAndSize")}
                                                            </Typography>
                                                        </CardActions>
                                                    </Card>
                                                    {errors.incomeTaxProof && <FormHelperTextStyled>{displayErrorMessage(errors.incomeTaxProof?.message) as string}</FormHelperTextStyled>}
                                                </Grid>
                                            </React.Fragment>
                                        }
                                    </Grid>
                                </Grid>
                                {fields.map((item, index) => {
                                    return (
                                        <Grid
                                            item
                                            xs={12}
                                            sm={12}
                                            md={12}
                                            lg={12}
                                            xl={12}
                                            key={index}
                                            paddingTop={"1rem"}
                                        >
                                            <Grid
                                                item
                                                xs={12}
                                                sm={12}
                                                md={12}
                                                lg={12}
                                                xl={12}
                                                paddingBottom={"1rem"}
                                            >
                                                <DocumentSubtitleAdditional label={t("text.attachDocument")} />
                                            </Grid>
                                            <DocumentAdditional
                                                index={index}
                                                title={t("text.add")}
                                                name={`documents.${index}.documentProof`}
                                                register={register}
                                                errors={errors}
                                                remove={remove}
                                                getValues={getValues}
                                                headertitle={t("text.incomeTax") as string}
                                                subheader={t("text.sendCompleteDocument") as string}
                                                uploading={uploading}
                                                setUploading={setUploading}
                                                canRemove={fields.length === (index + 1)}
                                                step='income-powerOfAttorney-register'
                                            />
                                        </Grid>
                                    );
                                })}

                                <Grid
                                    item
                                    xs={12}
                                    sm={12}
                                    md={12}
                                    lg={12}
                                    xl={12}
                                    paddingTop={"1rem"}

                                >
                                    <Button
                                        variant="outlined"
                                        label={t("text.addDocument")}
                                        startIcon={<Add />}
                                        onClick={() => append({
                                            id: "",
                                            fileOriginalName: "",
                                            fileSize: "",
                                            fileType: "",
                                            documentProof: "",
                                        })}
                                        disabled={!(fields.length <= 1) || uploading}
                                    />
                                </Grid>
                                <Grid
                                    item
                                    xs={12}
                                    sm={12}
                                    md={12}
                                    lg={12}
                                    xl={12}
                                >
                                    <Grid
                                        container
                                        spacing={2}
                                    >
                                        <Grid
                                            item
                                            xs={6}
                                            sm={6}
                                            md={6}
                                            lg={6}
                                            xl={6}
                                        >
                                            <Button
                                                variant="outlined"
                                                label={t("text.previousStep")}
                                                onClick={previous}
                                                disabled={submitting || uploading}
                                                fullWidth
                                            />
                                        </Grid>
                                        <Grid
                                            item
                                            xs={6}
                                            sm={6}
                                            md={6}
                                            lg={6}
                                            xl={6}
                                        >
                                            <LoadingButton
                                                type="submit"
                                                variant="contained"
                                                label={t("text.nextStep")}
                                                loading={submitting}
                                                fullWidth
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </React.Fragment>
                }
            </Grid>
        </form >
    );
}